import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import {getSubscriptionHistoryApi, getPlanByGeofencesApi} from '../../services/subscriptionServices';
import {convertQueryStringToObject} from '../../utils/common';
import {customDisplayCurrency} from '../../utils/common';
import './style.css';
import {convertDateTimeToDate} from '../../utils/date_time';

const current_day = new Date();
const yesterday = new Date(current_day.setDate(current_day.getDate() - 1));
const last_30_days = new Date().setDate(yesterday.getDate() - 29);
const last_31_days = new Date().setDate(yesterday.getDate() - 30);
const last_60_days = new Date().setDate(yesterday.getDate() - 59);
const last_61_days = new Date().setDate(yesterday.getDate() - 60);
const last_90_days = new Date().setDate(yesterday.getDate() - 89);

/**
 * Subscription Landing Page
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      first_history: [],
      second_history: [],
      third_history: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const urlParamStr = convertQueryStringToObject(window.location.search);

    const userId = urlParamStr?.userId;
    const geofence_id = urlParamStr?.geofenceId;

    await getPlanByGeofencesApi(geofence_id ? geofence_id : '', 200)
        .then((response) => {
          if (response && response.status === 200) {
            this.setState({plans: response.result?.filter((element) => element.is_main_plan)});
          } else {
            this.setState({plans: []});
          }
        })
        .catch((error) => this.setState({plans: []}));

    getSubscriptionHistoryApi(userId, convertDateTimeToDate(new Date(last_30_days)), convertDateTimeToDate(yesterday), geofence_id).then((response) =>
      this.setState({first_history: response.result}),
    );
    getSubscriptionHistoryApi(userId, convertDateTimeToDate(new Date(last_60_days)), convertDateTimeToDate(new Date(last_31_days)), geofence_id).then(
        (response) => this.setState({second_history: response.result}),
    );
    getSubscriptionHistoryApi(userId, convertDateTimeToDate(new Date(last_90_days)), convertDateTimeToDate(new Date(last_61_days)), geofence_id).then(
        (response) => this.setState({third_history: response.result}),
    );
  }

  /**
   * formatDisplayDateTime
   * @param {String} stringISO
   * @return {String}
   */
  formatDisplayDateTime = (stringISO) => {
    if (!stringISO) return '記録なし';
    return stringISO.substring(0, 4) + '.' + stringISO.substring(5, 7) + '.' + stringISO.substring(8, 10);
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    const subscriptionsDetail = [
      {
        title: t('sub.title1'),
        date:
          this.formatDisplayDateTime(new Date(last_30_days)?.toISOString()) +
          '-' +
          this.formatDisplayDateTime(yesterday?.toISOString()),
        price: this.state.first_history?.reduce((a, {cost}) => a + cost, 0) || 0,
      },
      {
        title: t('sub.title2'),
        date:
          this.formatDisplayDateTime(new Date(last_60_days)?.toISOString()) +
          '-' +
          this.formatDisplayDateTime(new Date(last_31_days)?.toISOString()),
        price: this.state.second_history?.reduce((a, {cost}) => a + cost, 0) || 0,
      },
      {
        title: t('sub.title3'),
        date:
          this.formatDisplayDateTime(new Date(last_90_days)?.toISOString()) +
          '-' +
          this.formatDisplayDateTime(new Date(last_61_days)?.toISOString()),
        price: this.state.third_history?.reduce((a, {cost}) => a + cost, 0) || 0,
      },
    ];

    return (
      <Container maxWidth="xs" style={{height: '100vh'}} className="landing-page">
        <Grid container className="mt-18">
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img
              src={`${process.env.PUBLIC_URL}/images/subscription/banner_subscription_${localStorage.getItem(
                  'i18nextLng',
              )}.png`}
              alt="img"
            />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60 list-content">
            <span className="font-18 font-weight-600 item-content" style={{padding: '0 10px'}}>{t('sub.description1')}</span>
            <div className="item-content">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/logo_rotate_180_degree.png`}
                alt="img"
                className="mt-24"
              />
            </div>
            <span className="font-18 font-weight-600 mt-12 item-content">{t('sub.description2')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48 list-content">
            <span className="font-14 font-weight-600 pt-6 item-content">{t('sub.mobi_define')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content">{t('sub.mobi_define1')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content" style={{padding: '0 6px'}}>{t('sub.mobi_define2')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content">{t('sub.mobi_define3')}</span>
          </Grid>
        </Grid>
        <Grid container className="mt-60 background-image">
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className="font-40 font-weight-600">{t('sub.feature')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8">
            <span className="font-18 text-center font-weight-600">{t('sub.feature_description')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/wallet_logo.png`}
                alt="Wallet"
                style={{width: 70}}
              />
            </Grid>
            <Grid className="width_100">
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
                <span className="font-18 font-weight-600 item-content">
                  {t('sub.feature_description1', {
                    amount: customDisplayCurrency(
                      this.state.plans[0]?.plan_prices[0]?.amount || 0,
                      this.state.plans[0]?.plan_prices[0]?.currency_code || 'JPY',
                    ),
                  })}
                </span>
                <span className="font-18 font-weight-600 item-content auto-font">
                  {t('sub.feature_description1.2')}
                </span>
                <span className="font-18 font-weight-600 item-content padding-content-0 auto-font">
                  {/* {this.state.plans[0]?.number_of_unit ? this.state.plans[0]?.number_of_unit : 0}  */}
                  ({t('sub.feature_description2')})
                </span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-12 list-content">
                <span className="font-14 item-content">{t('sub.feature_description3')}</span>
                <span className="font-14 item-content">{t('sub.feature_description4')}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <span className={this.props.i18n.language === 'vi' ? 'font-17 font-weight-600' : 'font-18 font-weight-600'}>{t('sub.feature_description5')}</span>
            </Grid>
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              container
              className="mt-12 mb-24 margin-item-0 background-grey"
            >
              {subscriptionsDetail.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                  container
                  className="margin-item-18"
                  key={index}
                >
                  <Grid item xs={this.props.i18n.language === 'vi' ? 9 : 7} justify="flex-start" alignItems="center" container>
                    <span className="font-11 font-weight-600">{item.title}</span>
                    <span className="font-9 pt-6">{item.date}</span>
                  </Grid>
                  <Grid item xs={this.props.i18n.language === 'vi' ? 3 : 5} justify="flex-start" alignItems="center" container>
                    <span className={Number(item.price) !== 0 ? 'font-12 font-weight-600' : 'font-24 font-weight-600'}>
                      {customDisplayCurrency(
                          item.price,
                        this.state.plans[0]?.plan_prices[0]?.currency_code || 'JPY',
                      )}
                    </span>
                  </Grid>
                  <hr className="width_100 divider_padding_inner"></hr>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img src={`${process.env.PUBLIC_URL}/images/subscription/car_logo.png`} alt="Car" style={{width: 70}} />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-17' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope2_description1')}
              </span>
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-17' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope2_description2')}
              </span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('sub.scope2_description3')}</span>
              <span className="font-14 item-content item-pre-line">{t('sub.scope2_description4')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/position_logo.png`}
                alt="Position"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-17' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope1_description1')}
              </span>
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-17' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope1_description2')}
              </span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 list-content">
              <span className="font-14 item-content">{t('sub.scope1_description3')}</span>
              <span className="font-14 item-content">{t('sub.scope1_description4')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 mb-36">
              <a
                onClick={() => window.webkit?.messageHandlers.test.postMessage('GEOFENCE')}
                className="font-12 color-success font-weight-600"
                id="geofence"
                href="geofence"
              >
                {t('sub.scope1_description5')}
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 mb-75 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/family_logo.png`}
                alt="Car"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-16' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope3_description1', {
                  fee: customDisplayCurrency(
                    this.state.plans[0]?.plan_prices[0]?.extra_fee || 0,
                    this.state.plans[0]?.plan_prices[0]?.currency_code || 'JPY',
                  ),
                })}
              </span>
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-16' : 'font-weight-600 item-content font-18'}>
                {t('sub.scope3_description2', {
                  fee: customDisplayCurrency(
                    this.state.plans[0]?.plan_prices[0]?.extra_fee || 0,
                    this.state.plans[0]?.plan_prices[0]?.currency_code || 'JPY',
                  ),
                })}
              </span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 list-content">
              <span className="font-14 item-content">
                {t('sub.scope3_description3', {
                  member: this.state.plans[0]?.limit_family_user ?
                    Number(this.state.plans[0]?.limit_family_user - 1) :
                    0,
                })}
              </span>
              <span className="font-14 item-content">{t('sub.scope3_description4')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 mb-36">
              <a
                onClick={() => window.webkit?.messageHandlers.test.postMessage('FAMILY')}
                className="font-12 color-success font-weight-600"
                id="family"
                href="family"
              >
                {t('sub.scope3_description5')}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation('translations')(Index);
