/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './styles.css';

/**
 * HowToUse
 * @return {HTMLElement}
 */
const HowToUse = () => {
  return (
    <article className="how-to-use">
      <section id="hero">
        <h1 className="hero_img">
          <img
            src="/images/how-to-use/pc_mv.jpg"
            alt="アプリでクレジットカードを登録するとQRコードをかざして乗車できます。"
            className="d-none d-sm-block img-fluid"
          />
          <img
            src="/images/how-to-use/sp_mv.jpg"
            alt="アプリでクレジットカードを登録するとQRコードをかざして乗車できます。"
            className="d-block d-sm-none img-fluid"
          />
        </h1>
      </section>
      <section id="traffic" className="container">
        <h2>対象となる交通会社</h2>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4 img_box">
            <img
              src="/images/how-to-use/traffic_logo_2.png"
              alt="京都丹後鉄道、丹後海陸交通株式会社、全但株式会社"
              className="img-fluid"
            />
          </div>

          <div className="col-sm-12 col-md-8 text">
            <h3>利用できる路線</h3>
            <dl>
              <dt>京都丹後鉄道</dt>
              <dd>
                全区間（快速・普通列車のみ）
                <br />
                ※特急列車は自由席を含めご乗車できません。また、一部の快速・普通列車においてもご利用いただけない列車があります。
              </dd>
            </dl>
          </div>
        </div>
      </section>
      <section id="howto">
        <div className="container">
          <h2>利用方法</h2>
        </div>
        <div className="bg_color">
          <div className="block container">
            <h3>クレジットカードの登録</h3>
            <p className="sub_text">
              アプリのマイページからクレジットカードを登録。
              <br />
              ※既に登録済みの方は本作業を行う必要はございません。
            </p>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb01.png" alt="01" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">アプリのマイページから「決済設定」を開く。</dd>
                  <dd className="img_box image_card">
                    <img src="/images/how-to-use/card_01.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb02.png" alt="02" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">カード情報を入力して登録。</dd>
                  <dd className="img_box">
                    <img src="/images/how-to-use/card_02.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb03.png" alt="03" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">登録完了。</dd>
                  <dd className="img_box">
                    <img src="/images/how-to-use/card_03.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
            </div>
          </div>{' '}
          {/* block// */}
        </div>
        {/* bg_color// */}
        <div className="block container mt-5">
          <h3>乗車時から乗車中の動作</h3>
          <p className="sub_text">駅改札や車両に設置されている乗車用リーダーにアプリのQRコードをタッチ。</p>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <dl>
                <dt>
                  <img src="/images/how-to-use/nb01.png" alt="01" className="img-fluid" />
                </dt>
                <dd className="sub_text">アプリの「チケット」メニューを開くと、画面上部にQRコードが表示されます。</dd>
                <dd className="img_box image_qr">
                  <img src="/images/how-to-use/qr_01.png" alt="" className="img-fluid" />
                </dd>
              </dl>
            </div>
            <div className="col-sm-12 col-md-4">
              <dl>
                <dt>
                  <img src="/images/how-to-use/nb02.png" alt="02" className="img-fluid" />
                </dt>
                <dd className="sub_text">
                  QRコードを乗車用リーダーにかざしてください。
                  <br />
                  <small>※読取完了まで約1～2秒かかります。</small>
                </dd>
                <dd className="img_box">
                  <img src="/images/how-to-use/qr_02.png" alt="" className="img-fluid" />
                </dd>
              </dl>
            </div>
            <div className="col-sm-12 col-md-4">
              <dl>
                <dt>
                  <img src="/images/how-to-use/nb03.png" alt="03" className="img-fluid" />
                </dt>
                <dd className="sub_text">
                  リーダーから「ピッピッ」という音と、「<span className="txt_color">○PASS</span>
                  」の画面が表示されたら認証完了です。アプリでは「乗車中」と表示が変わります。
                  <br />
                  <small>※QRコードをかざしてからアプリの表示が切り替わるまで約5分程度かかります。</small>
                </dd>
                <dd className="img_box">
                  <img src="/images/how-to-use/qr_03.png" alt="" className="img-fluid" />
                </dd>
              </dl>
            </div>
          </div>
        </div>{' '}
        {/* block// */}
        <div className="bg_color">
          <div className="block container">
            <h3>降車時から利用完了までの動作</h3>
            <p className="sub_text">駅改札や車両に設置されている降車用リーダーにアプリのQRコードをタッチ。</p>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb01.png" alt="01" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">
                    アプリの「チケット」メニューを開くと、画面上部にQRコードが表示されます。
                    <br />
                    <small>※乗車時と同じQRコードです。</small>
                  </dd>
                  <dd className="img_box image_qr">
                    <img src="/images/how-to-use/qr_01.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb02.png" alt="02" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">
                    QRコードを降車用リーダーにかざしてください。
                    <br />
                    <small>※読取完了まで約1～2秒かかります。</small>
                  </dd>
                  <dd className="img_box">
                    <img src="/images/how-to-use/qr_02.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
              <div className="col-sm-12 col-md-4">
                <dl>
                  <dt>
                    <img src="/images/how-to-use/nb03.png" alt="03" className="img-fluid" />
                  </dt>
                  <dd className="sub_text">
                    リーダーから「ピッピッ」という音と、「<span className="txt_color">○利用料金</span>
                    」の画面が表示されたらお支払い完了です。アプリでは、マイページから利用履歴を確認できます。
                    <br />
                    <small>※QRコードをかざしてからアプリの表示が切り替わるまで約5分程度かかります。</small>
                  </dd>
                  <dd className="img_box">
                    <img src="/images/how-to-use/qr_04.png" alt="" className="img-fluid" />
                  </dd>
                </dl>
              </div>
            </div>
          </div>{' '}
          {/* block// */}
        </div>
        {/* bg_color// */}
      </section>
      <section id="faq" className="container mt-5 mb-5">
        <div className="acc-box" itemScope itemType="https://schema.org/FAQPage">
          <h2>よくあるご質問</h2>
          {/* FAQ01 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc01">
                  QRコードで利用可能なサービスについて
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc01"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>下記の交通事業者でご利用いただけます。</p>
                <ul className="list">
                  <li>京都丹後鉄道の快速・普通列車</li>
                </ul>
                <span>
                  ※京都丹後鉄道の特急列車は自由席を含めご乗車できません。また、一部の快速・普通列車においてもご利用いただけない列車があります。
                </span>
              </div>
            </dd>
          </dl>
          {/* FAQ02 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc02">
                  登録できるクレジットカードのブランドは？
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc02"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>JCB/VISA/MASTER/AMEXカードがご利用頂けます。</p>
              </div>
            </dd>
          </dl>
          {/* FAQ03 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc03">
                  デビットカードの登録はできますか？
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc03"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>デビットカードの登録はできません。</p>
              </div>
            </dd>
          </dl>
          {/* FAQ04 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc04">
                  登録したクレジットカードの削除や変更はできますか？
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc04"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>削除は出来ませんが、マイページの決済設定の画面から変更が可能です。</p>
              </div>
            </dd>
          </dl>
          {/* FAQ05 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc05">
                  利用履歴はどこから確認できますか？
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc05"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>マイページの注文履歴から、利用日時、利用区間、利用料金をご確認頂けます。</p>
              </div>
            </dd>
          </dl>
          {/* FAQ06 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc06">
                  圏外でも利用できますか？
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc06"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>
                  申し訳ございませんが、圏外の場合はご利用いただけませんので、整理券をお取りいただき、現金精算をお願い致します。
                </p>
              </div>
            </dd>
          </dl>
          {/* FAQ07 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc07">
                  乗車中のままになっていて、乗車用リーダーにかざしてもエラーになってしまいます。
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc07"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>
                  前回ご利用いただいた際に、降車処理が正しくされていないため、現在の状態ではご利用いただけません。
                  <br />
                  カスタマーセンターまでご連絡ください。
                  <br />
                  【カスタマーセンター】 <br className="d-block d-sm-none" />
                  0570-666-447（11:00～18:00）
                </p>
              </div>
            </dd>
          </dl>
          {/* FAQ08 */}
          <dl className="inner mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <dt className="question">
              <h3 itemProp="name">
                <a href="javascript:void(0)" className="collapsed" data-toggle="collapse" data-target="#acc08">
                  QRリーダーが故障していて利用できません。
                </a>
              </h3>
            </dt>
            <dd
              className="collapse"
              id="acc08"
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="answer" itemProp="text">
                <p>ご迷惑をお掛けして申し訳ございません。整理券をお取りいただき、現金精算をお願い致します。</p>
              </div>
            </dd>
          </dl>
        </div>
        {/* acc-box */}
      </section>
      <br></br>
    </article>
  );
};

export default HowToUse;
