/* eslint-disable max-len */
export default [
  {
    title: 'Điều khoản và điều kiện sử dụng dịch vụ mobi dành riêng cho người dùng Việt Nam',
    noneDescription: true,
  },
  {
    description:
      'Điều khoản và điều kiện sử dụng dịch vụ mobi (Dưới đây gọi tắt là "Điều khoản này") là văn bản quy định các điều kiện sử dụng dịch vụ mobi chủ yếu do WILLERS PTE PLD. (Dưới đây gọi tắt là "Công ty chúng tôi") vận hành và cung cấp thông qua "Ứng dụng mobi Community Mobility" (Dưới đây gọi tắt là "Ứng dụng này").',
  },
  {
    title: 'Điều 1. Quy định chung',
    center: true,
    afterNoneDescription: true,
    list1: [
      {
        title:
          'Điều khoản này là điều khoản chi tiết riêng trong điều khoản sử dụng ứng dụng mobi Community Mobility (Dưới đây gọi tắt là “điều khoản ban đầu”), và điều khoản này sẽ được ưu tiên áp dụng trước điều khoản gốc trừ khi có quy định khác.',
      },
      {
        title:
          'Người đăng ký sử dụng dịch vụ mobi được coi là đã đồng ý với các điều khoản này, các tài liệu hướng dẫn quy trình đính kèm và điều khoản sử dụng, v.v. (Dưới đây gọi tắt là "Các điều khoản và điều kiện này") tại thời điểm đăng ký. (Người đăng ký dịch vụ sau khi đồng ý với các điều khoản và điều kiện này dưới đây gọi tắt là "người dùng".)',
      },
      {
        title:
          'Theo các điều khoản ban đầu, công ty chúng tôi sẽ là nhà cung cấp và dịch vụ mobi sẽ là một dịch vụ riêng lẻ.',
      },
      {
        title:
          'Công ty chúng tôi có thể thay đổi nội dung của các điều khoản này theo quyết định của riêng mình trong các trường hợp sau. Trong trường hợp này, các nội dung thay đổi của điều khoản này sẽ được áp dụng cho việc sử dụng sau ngày thay đổi. Ngoài ra, khi công ty chúng tôi thay đổi nội dung của các điều khoản này, chúng tôi sẽ thông báo trước cho người dùng về những nội dung đã thay đổi trong một khoảng thời gian hợp lý.',
        list3: {
          content: [
            {
              title: 'Khi việc thay đổi nội dung các điều khoản này được thực hiện vì lợi ích chung của người dùng.',
            },
            {
              title:
                'Khi việc thay đổi nội dung các điều khoản này không vi phạm mục đích của các điều khoản ban đầu, và khi thấy sự cần thiết phải thay đổi, sự phù hợp của nội dung thay đổi, nội dung thay đổi và các trường hợp khác liên quan đến thay đổi đều hợp lý.',
            },
          ],
        },
      },
      {
        title:
          'Ngay cả khi một số quy định của các Điều khoản này bị pháp luật hoặc phán quyết vô hiệu hoặc bị hủy bỏ thì tính hiệu lực, tính hợp pháp và hiệu lực ràng buộc pháp lý của các điều khoản còn lại vẫn sẽ còn hiệu lực.',
      },
    ],
  },
  {
    title: 'Điều 2. Nội dung dịch vụ mobi',
    center: true,
    list1: [
      {
        title:
          'Trong dịch vụ mobi, người dùng có thể được cung cấp các dịch vụ chỉ định trong từng mục sau đây.',
        list3: {
          content: [
            {
              title:
                'Dịch vụ vận chuyển hành khách bằng ô tô (Dưới đây gọi tắt là “Dịch vụ vận chuyển này” ) bởi các Công ty vận chuyển hành khách bằng ô tô (sau đây gọi là "Công ty vận chuyển”) liên kết với Công ty chúng tôi tại các điểm lên xe/ xuống xe do Công ty chúng tôi quy định trong khoảng cách nơi lên xe và nơi xuống xe mà người dùng đặt thông qua Ứng dụng.',
            },
            {
              title:
                'Dịch vụ thử nghiệm thực tế sử dụng trong trong khoảng thời gian do Công ty quy định.',
            },
          ],
        },
      },
      {
        title:
          'Hợp đồng dịch vụ vận chuyển được ký kết giữa công ty chúng tôi, người dùng và công ty vận chuyển phải phù hợp với điều lệ vận chuyển do công ty vận chuyển quy định riêng. ',
      },
    ],
  },
  {
    title: 'Điều 3. Hủy dịch vụ vận chuyển',
    center: true,
    description:
      'Người dùng và người thân sống chung đã đăng ký sử dụng gói dịch vụ (Dưới đây gọi tắt là "những người dùng") sau khi đăng ký sử dụng dịch vụ vận chuyển của gói dịch vụ mobi có thể hủy đăng ký dịch vụ vận chuyển này bất cứ lúc nào trong khoảng thời gian cho đến khi xe của công ty vận chuyển đến điểm khởi hành.',
  },
  {
    title: 'Điều 4. Trách nhiệm và nghĩa vụ người dùng',
    center: true,
    list1: [
      {
        title:
          'Những người dùng khi nhận cung cấp dịch vụ vận chuyển, phải tuân thủ theo các điều kiện quy định trong Luật Giao thông Đường bộ và hợp đồng vận chuyển chi tiết riêng, và chỉ thị của thành viên tổ lái xe phục vụ dịch vụ vận chuyển. Ngoài ra, trường hợp thành viên tổ lái xe của xe cung cấp dịch vụ nhận định một cách hợp lý rằng những người dùng đã vi phạm hoặc có hành vi có thể vi phạm quy định tại điều này, thì những thành viên tổ lái xe với tư cách là người quản lý xe có thể từ chối lên xe hoặc ra lệnh xuống xe ngay lập tức với những người dùng này.',
      },
      {
        title:
          'Những người dùng không được tự ý thực hiện lặp đi lặp lại hành vi đăng ký hoặc hủy dịch vụ.',
      },
      {
        title:
          'Dịch vụ vận chuyển đã được đăng ký trong gói sử dụng dịch vụ mobi, chỉ dành cho chính những người dùng đã đăng ký sử dụng. Người dùng chung cùng những người dùng sẽ không thể lên xe qua dịch vụ mobi nếu không đăng ký dịch vụ vận chuyển riêng. ',
      },
    ],
  },
  {
    title: 'Điều 5. Ứng phó khi gặp tai nạn trong quá trình vận chuyển',
    center: true,
    list1: [
      {
        title:
          'Trường hợp người dùng bị thiệt hại do tai nạn hoặc sự cố (Dưới đây gọi tắt là "tai nạn") xảy ra khi sử dụng dịch vụ vận chuyển (bao gồm cả thời gian lên xuống xe), người dùng sẽ trao đổi và giải quyết với Công ty vận chuyển dựa trên các điều khoản quy định tại điều lệ vận chuyển được quy định bởi Công ty vận chuyển.',
      },
      {
        title:
          'Công ty chúng tôi sẽ không chịu trách nhiệm với bất kỳ thiệt hại nào xảy ra với người dùng do tai nạn theo quyết định của mình.',
        list3: {
          content: [
            {
              title:
                'Trường hợp những người dùng hoặc Công ty vận chuyển khởi kiện để giải quyết tranh chấp theo Điều này, Tòa án tại địa chỉ đã đăng ký của Công ty chúng tôi cho vụ kiện dân sự tại nước Cộng hòa Singapore sẽ là tòa án sơ thẩmcó thẩm quyền quyết định cao nhất. Tuy nhiên, trường hợp được phép khởi kiện tại một tòa án khác theo quy định của Luật xử phạt cưỡng chế thì sẽ áp dụng theo quy định đó.',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'Điều 6. Thông tin người dùng',
    center: true,
    list1: [
      {
        title:
          'Người dùng bằng cách đăng ký dịch vụ mobi, sẽ đồng ý cho Công ty chúng tôi và Công ty WILLER Inc. cùng sử dụng chung các thông tin cá nhân như họ tên, giới tính và thông tin liên lạc mà người dùng đã đăng ký trong ứng dụng này cho mục đích cung cấp dịch vụ mobi theo các quy định dưới đây và đồng ý với chính sách bảo mật và chính sách xử lý thông tin cá nhân do Công ty chúng tôi đặt ra.',
      },
      {
        title:
          'Công ty sẽ quản lý một cách thích hợp thông tin cá nhân của người dùng ở mục trên và thông tin thu được trong quá trình cung cấp dịch vụ mobi theo chính sách bảo mật của Công ty.',
      },
      {
        title:
          'Chính sách bảo mật, chính sách sử dụng thông tin cá nhân liên quan ở mục trên và thông tin liên hệ trao đổi về thông tin cá nhân sẽ được đăng tải trong mục chính sách bảo mật (privacy policy) trong ứng dụng này.',
      },
    ],
  },
  {
    title: 'Điều 7. Loại bỏ các thế lực chống đối xã hội',
    center: true,
    list1: [
      {
        title:
          'Người dùng phải tuyên bố và cam kết rằng họ không thuộc bất kỳ đối tượng nào sau đây và không có mối quan hệ giao lưu mật thiết với cá nhân hoặc nhóm nào trong các mục sau đây.',
        list3: {
          content: [
            {
              title: 'Băng đảng xã hội đen.',
            },
            {
              title: 'Thành viên của băng đảng xã hội đen (Bao gồm cả thành viên liên kết. Dưới đây cũng tương tự.',
            },
            {
              title:
                'Các công ty liên quan đến băng đảng xã hội đen hoặc tổ chức có thành viên của băng đảng xã hội đen góp vốn hoặc giữ các vị trí quan trọng liên quan đến điều hành nghiệp vụ.',
            },
            {
              title:
                'Tổ chức tống tiền, tổ chức xã hội đen đàn áp người yếu thế thu lợi bất chính núp bóng hoạt động ủng hộ phong trào xã hội và các lực lượng chống đối xã hội khác.',
            },
            {
              title: 'Tổ chức tội phạm trí tuệ đặc thù, các nhóm khác thuộc các lực lượng chống đối xã hội hoặc các thành viên của chúng.',
            },
            {
              title:
                'Các thành viên khác liên quan đến các tổ chức trên.',
            },
          ],
        },
      },
      {
        title:
          'Người dùng phải tuyên bố và cam kết rằng rằng họ sẽ không tự mình hoặc sử dụng bên thứ ba thực hiện bất kỳ hành vi nào sau đây.',
        list3: {
          content: [
            {
              title:
                'Hành vi bạo lực hoặc hành vi vô lý vượt quá trách nhiệm pháp lý.',
            },
            {
              title: 'Có hành vi đe dọa hoặc sử dụng bạo lực liên quan đến giao dịch.',
            },
            {
              title:
                'Có hành vi truyền bá tin đồn, sử dụng quyền lực hoặc sức mạnh để gây tổn hại đến uy tín của bên kia hoặc can thiệp vào công việc kinh doanh của bên kia.',
            },
            {
              title: 'Các hành vi khác tương tự với hành vi ở các mục trên.',
            },
          ],
        },
      },
      {
        title:
          'Công ty chúng tôi và Công ty vận tải có thể tạm dừng ngay việc cung cấp dịch vụ mobi và từ chối cung cấp dịch vụ sau đó nếu người dùng vi phạm hoặc xác định là đang vi phạm các quy định công bố tại hai khoản trên. Trong trường hợp này, người dùng đã bị Công ty chúng tôi và Công ty vận tải đánh giá là đã vi phạm hoặc đang vi phạm các nội dung đã công bố sẽ phải bồi thường toàn bộ những thiệt hại mà Công ty chúng tôi và Công ty vận tải phải gánh chịu từ việc này (Bao gồm nhưng không giới hạn khoản lợi nhuận tổn thất do tổn hại danh tiếng, phí luật sư, v.v.).',
      },
    ],
  },
  {
    title: 'Điều 8. Áp dụng sửa đổi cho điều khoản ban đầu',
    center: true,
    description:
      'Các quy định từ Điều 4 đến Điều 11 của điều khoản ban đầu sẽ áp dụng theo sửa đổi phù hợp với điều khoản này. "Công ty chúng tôi" trong điều khoản ban đầu được áp dụng theo những sửa đổi thích hợp sẽ là WILLERS PTE LTD trong điều khoản này và "người dùng" sẽ được đọc lại là những người dùng quy định trong điều khoản này.',
  },
];
