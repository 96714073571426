/* eslint-disable max-len */
const en = {
  translations: {
    // Route
    'route.home': 'Home',
    'route.login': 'Login',
    'route.registration': 'Registration',
    'route.logout': 'Logout',
    'route.verify_mobile_account': 'Verify Mobile Account',
    'route.mobile_reissue_password': 'Reissue Password',

    // Button common
    'common.btnAgree': 'Agree',
    'common.btnAdd': 'Add',
    'common.btnUpdate': 'Update',
    'common.btnDelete': 'Delete',
    'common.btnSend': 'Send',
    'common.btnChange': 'Change',
    'common.btnLogin': 'login',
    'common.btnLogout': 'Logout',
    'common.btnRegister': 'Register',
    'common.btnCreate': 'Create',
    'common.btnReturn': 'Return',
    'common.btnCancel': 'Cancel',
    'common.btnResetSearch': 'Search condition reset',
    'common.btnSearch': 'Search',
    'common.btnReopen.Stop': 'Re-Open/Stop',
    'common.btnUploadCsv': 'CSV Output',
    'common.btnDetail': 'Detail',
    'common.btnClose': 'Close',
    'common.btnBulkRegistration': 'Collective Register',
    'common.ja': 'Japanese',
    'common.en': 'English',
    'common.vi': 'Vietnamese',

    // Common message code
    'messageCode.updateConfirm': 'Do you want to update this {{field}}?',
    'messageCode.deleteConfirm': 'Do you want to delete this {{field}}?',
    'messageCode.changePasswordConfirm': 'Update your password. Is it OK?',
    'messageCode.accountInviteConfirm': 'Send an email. Is it OK?',
    'messageCode.deleteConfirmProduct': 'Do you want to delete the product?',
    'messageCode.deleteConfirmSupplier': 'Do you want to delete the supplier?',
    'messageCode.deleteConfirmDriver': 'Do you want to delete the driver?',
    'messageCode.deleteConfirmImageMaster': 'Do you want to delete the image master?',
    'messageCode.updateConfirmDriver': 'Do you want to update the driver?',
    'messageCode.updateConfirmVehicle': 'Do you want to update the vehicle?',
    'messageCode.deleteConfirmVehicle': 'Do you want to delete the vehicle?',
    'messageCode.verifyEmailToken': 'The email address you registered has been successfully authenticated.',

    // Common error field message
    'errorFields.invalidDateMessage': 'Invalid Date Format',
    'errorFields.maxDateMessage': 'Date should not be after maximal date',
    'errorFields.minDateMessage': 'Date should not be before minimal date',
    'errorFields.passwordRequired': 'Please enter the Password.',
    'errorFields.emailRequired': 'Please enter the Email.',
    'errorFields.emailFormat': 'Invalid email address format.',
    'errorFields.textLength': 'Please enter within 128 characters',
    'errorFields.text256Length': 'Please enter within 256 characters',
    'errorFields.cannotChange': 'Can not change',
    'errorFields.birthdayRequired': 'Please enter your Birthday.',
    'errorFields.accountNameRequired': 'Please enter your Account Name.',
    'errorFields.rePasswordRequired': 'Please enter the Re-enter Password.',
    'errorFields.phoneNumberRequired': 'Please enter your Phone Number.',
    'errorFields.confirmPasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.oldPasswordRequired': 'Please enter your current password.',
    'errorFields.newPasswordRequired': 'Please enter a new password.',
    'errorFields.passwordMin': 'Please enter at least 6 characters including lowercase letters.',
    'errorFields.confirmPasswordRequired': 'Please enter the password again.',
    'errorFields.personChargeRequired': 'Please enter the Person in charge.',
    'errorFields.firstNameRequired': 'Please enter the First Name.',
    'errorFields.lastNameRequired': 'Please enter the Last Name.',
    'errorFields.addressRequired': 'Please enter the Address.',
    'errorFields.rePasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.businessRequired': 'Please enter the Business name.',
    'errorFields.mobileRequired': 'Please enter the phone number.',
    'errorFields.checkPhoneNumberMatch': 'The phone number do not match. Please try again.',
    'errorFields.sizeFile': 'Image size must be greater than 200KB and not exceed 500KB.',
    'errorFields.statusRequired': 'Please choose the status.',
    'errorFields.typeRequired': 'Please choose the type.',
    'errorFields.industryRequired': 'Please choose the industry.',
    'errorFields.countryRequired': 'Please choose the country.',
    'errorFields.supplierNameRequired': 'Please choose the supplier name.',
    'errorFields.supplierCodeRequired': 'Please choose the supplier code.',
    'errorFields.supplierAddressRequired': 'Please choose the address.',
    'errorFields.facilityNameRequired': 'Please enter the facility name.',
    'errorFields.areaRequired': 'Please choose the area.',
    'errorFields.languageRequired': 'Please choose the language.',
    'errorFields.holidayRequired': 'Please choose the holiday.',
    'errorFields.feeRequired': 'Please enter the fee.',
    'errorFields.feeTypeRequired': 'Please choose the type fee.',
    'errorFields.errorPrice': 'Please enter the charges.',

    // Message error call API
    'error.file.size.less.than.200KB': 'File size not less than 200kb',
    'error.file.size.is.empty': 'The file cannot be left blank',
    'error.no.record': 'No records',
    'error.already.exist.or.has.been.invited': 'Email already exist or has been invited.',
    'error.account.user.not.found': 'Account user not found.',
    'error.business.not.found': 'Business not found.',
    'error.role.not.found': 'Role not found.',
    'error.permission.not.found': 'Permission not found.',
    'error.api.role.permission.unknown': 'Api role-permission unknown.',
    'error.api.token.not.match': 'Api token not match.',
    'error.link.not.found': 'Link not found.',
    'error.user.not.resumed': 'Account user not resumed.',
    'error.link.expired': 'Link expired.',
    'error.email.not.found': 'Email not found.',
    'error.email.password.invalid': 'Password invalid.',
    'error.change.password': 'Current password is incorrect.',
    'error.newPassword.must.not.equals.currentPassword': 'The current password and new password must not match.',
    'error.not.found.user': 'User not found.',
    'error.user.not.exists': 'User does not exist',
    'error.email.exists': 'Mail does not exist',
    'error.mobile.exists': 'Mobile does not exist',
    'error.reissue.password.token_expired': 'Token already exists',
    'error.reissue.password.token_not_match': 'Bad token format',
    'error.member.not.exists': 'Member does not exist',
    'invalid.email': 'Invalid email',
    'invalid.timeTo': 'Invalid time to',
    'invalid.timeFrom': 'Invalid time from',
    'error.time.from.not.after.time.to': 'The start time is less than the end time',
    'user.email.token.invalid': 'Failed to authenticate email address.\n' +
                                'Please check the authentication URL and try again.',
    'error.check.value.empty': 'Please check the values ​​to be entered',

    // Message success call API
    'api.invite.success': 'Invited accounts successfully.',
    'api.active.account.success': 'Active account successfully.',
    'api.add.supplier.success': 'Add supplier successfully.',
    'api.deleted.supplier.success': 'Delete supplier successfully',
    'api.delete.driver.success': 'Delete driver successfully',
    'api.delete.caution.success': 'Delete item successfully',
    'api.update.driver.success': 'Update driver successfully',
    'api.update.caution.success': 'Update item successfully',
    'api.create.driver.success': 'Create driver successfully',
    'api.updated.supplier.success': 'Updated supplier successfully.',
    'api.updated.account.success': 'Updated account successfully.',
    'api.execute.reissue.success': 'Password reissue successfully.',
    'Api.success.change.password': 'Change password successfully.',
    'api.updated.member.success': 'Updated member successfully.',
    'api.created.member.success': 'Created member successfully',
    'api.reissue_password.request.success': 'Request reissue password successfully',
    'api.reissue_password.verify.success': 'Verify reissue password successfully',
    'api.reissue_password.confirm.success': 'Confirm reissue password successfully',
    'api.canceled.member.success': 'Cancelled member successfully',
    'api.deleted.facility.success': 'Delete facilities successfully',
    'api.updated.facility.success': 'Updated facility successfully.',
    'api.add.facility.success': 'Add facility successfully.',
    'api.facility.not.found': 'API facility not found',
    'api.delete.vehicle.success': 'Delete supplier\'s vehicle successfully.',
    'api.updated.vehicle.success': 'Updated supplier\'s vehicle successfully',
    'api.created.vehicle.success': 'Created supplier\'s vehicle successfully',
    'api.update.calendar.success': 'Updated calendar successfully.',
    'api.create.image.success': 'Create image successfully',
    'api.update.image.success': 'Update image successfully',
    'api.delete.image.success': 'Delete image successfully',
    'user.verify.email.token.success': 'Authentication succeeded',
    'user.verify.email.token.success2': 'Your email has been successfully authenticated.',

    // Home page
    'homePage.title': 'Home',

    // Registration page
    'registration.title': 'Account creation',
    'registration.accountType': 'Account type',
    'registration.authority': 'Authority',
    'registration.businessName': 'Business name',
    'registration.email': 'Email',
    'registration.contactName': 'Contact name',
    'registration.password': 'Password',
    'registration.rePassword': 'Re-enter password',
    'registration.phoneNumber': 'Phone number',

    // Error page UI
    'maintenance': 'We are currently down for maintenance.',
    'unauthorized': 'Whoops!!!  Access to this resource is denied',

    // validation
    'validation.required': 'Please enter the {{ field }}',
    'validation.email': 'Invalid email address format.',
    'validation.required.choose': 'Please choose the {{ field }}',
    'validation.numeric': '{{ field }} must be a number',
    'validation.length': 'Enter {{ field }} with {{ number }} characters or less.',
    'validation.between': '{{field}} must be greater than or equal to {{min}} and less than or equal to {{max}}',

    // common constant
    'currencyCode.VND': 'VND',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': '￥',

    // Jit landing page
    'jit.title': 'mobi shuttle',
    'jit.description1': 'A convenient mode of transportation to',
    'jit.description2': 'places you frequently visit',

    'jit.item1.title': 'For commuting to work or school',
    'jit.item1.description1': 'With advance reservations and fixed schedule,',
    'jit.item1.description2': 'there is no need to worry',
    'jit.item1.description3': 'about commuting problems.',

    'jit.item2.title': 'Travel with ease',
    'jit.item2.description1': 'Convenient travels from residential area',
    'jit.item2.description2': 'to work without any transfer while securing a seat.',
    'jit.item2.description3': '',

    'jit.item3.title1': 'Value for money',
    'jit.item3.title2': '',
    'jit.item3.description1': 'Enjoy fixed price with no peak hour surcharge.',
    'jit.item3.description2': '',
    'jit.item3.description3': '',

    'jit.btnMethod': 'How to book',
    'jit.btnRequest': 'Submit reservation',

    'jit.category1.title': 'Select your route and time',
    'jit.category1.description1': 'Depending on the reservation condition, the estimated departure and arrival times may differ OR The estimated departure and arrival times may differ depending on the reservation condition (depending on other reservations/reservation status; first come first serve).',
    'jit.category1.description2': '',
    'jit.category1.departure': 'Departure',
    'jit.category1.indication': '(Estimated)',
    'jit.category1.arrival': 'Arrival \r\n(Estimated) ',
    'jit.category1.deadline': 'Reservation cut-off time',
    'jit.category1.seats': 'Seats available',
    'jit.category1.thursday': 'THU',
    'jit.category1.month': 'Month',
    'jit.category1.day': 'Day',

    'jit.category2.title': 'Confirm number of passengers',
    'jit.category2.description1': 'Indicate number of passengers & choose your payment method.',
    'jit.category2.description2': '',

    'jit.category3.title': 'Reservation details',
    'jit.category3.description1': 'Reservation details will be stated accordingly,',
    'jit.category3.description2': 'while pending for reservation confirmation.',
    'jit.category3.description2.1': '',
    'jit.notify': '',

    // User Privacy
    'user_privacy.title1': 'In using the WILLERS app website',
    'user_privacy.title2': 'Privacy policy',
    'user_privacy.description1':
      'At WILLERSPTE.LTD., We consider that the protection of customer\'s personal information is a very important social responsibility in implementing services related to the travel business, which is our main business, and the business associated with them. For the purpose of proper handling, we have established the following privacy policy and are engaged in business.',
    'user_privacy.description2':
      'This privacy policy explains the handling of customer\'s personal information that we keep from you. Please read this privacy policy carefully before using our services and products. When you use the services and products provided by our company, we consider that you have fully understood and agreed to the contents of this privacy policy.',

    'user_privacy.item1.title': 'About the scope of privacy policy',
    'user_privacy.item1.description1':
      'The privacy policy applies to all personal information used in our business, including the personal information you enter when using our services and products.',
    'user_privacy.item1.description2': 'About voluntary provision of information',
    'user_privacy.item1.description3':
      'Some of the information that we can obtain can be refused at the will of the customer, and some information must be provided depending on the purpose of use, such as the name and address at the time of membership registration / reservation. If you do not wish us to provide you with specific information, please do not provide it to us. In this case, the services you receive may be affected, such as not being able to use all or part of our services, or not being able to use all or part of the functions of our website.',
    'user_privacy.item1.description4':
      'When you provide personal information to us, please specify in advance whether you agree to the acquisition and use of personal information according to the display on our website. You may withdraw your consent at any time. However, withdrawal of consent does not affect the legality of the processing taken by us prior to withdrawal.',

    'user_privacy.item2.title': 'Handling of personal information',
    'user_privacy.item2.description':
      'We will store any personal information that can be obtained from you on this site under strict control, use it only for the purpose of use described later, and unless it falls under any of the following, your personal information Will not be disclosed or provided to third parties. In addition, if we provide it to our affiliated companies, service providers, business partners, etc. to the extent necessary for the purpose, we will not disclose or provide it to a third party. Business partners are obliged by contract to manage personal information in the same way as the standards set by the Company.',
    'user_privacy.item2.list1': 'With the consent of the customer',
    'user_privacy.item2.list2': 'When requested to disclose or provide based on Singapore law',
    'user_privacy.item2.list3':
      'When it is necessary for the protection of human life, body or property, and it is difficult to obtain the consent of the customer.',
    'user_privacy.item2.list4':
      'When it is necessary for the national government or local public bodies to cooperate in carrying out public affairs, and there is a risk that obtaining the consent of the customer will hinder the performance of the affairs.',
    'user_privacy.item2.list5':
      'When disclosing or providing statistical data in a state where the person cannot be identified',

    'user_privacy.item3.title': 'About the information that we can obtain',
    'user_privacy.item3.list1.1': 'Thing about member registration, reservation',
    'user_privacy.item3.list1.2': 'Name, address, phone number, email address, credit card payment information, etc.',
    'user_privacy.item3.list2': 'Questionnaire',
    'user_privacy.item3.list3.1': 'Purchasing motivation, taste, etc.',
    'user_privacy.item3.list3.2': 'General web and app access information',
    'user_privacy.item3.list3.3': 'Domain name, access time, browser type, access device type, usage history, etc.',

    'user_privacy.item4.title': 'About the purpose of use',
    'user_privacy.item4.description':
      'We will use the personal information entrusted to us by our customers on this site for the following purposes.',
    'user_privacy.item4.list1': 'Provision and arrangement of the service you applied for, and management work',
    'user_privacy.item4.list2':
      'Sending notices by mail and e-mail of printed matter and other communication materials from our company',
    'user_privacy.item4.list3':
      'Statistical analysis and review to improve our site, products and services for non-personally identifiable information',
    'user_privacy.item4.list4': 'Advertising activity',

    'user_privacy.item5.title': 'About the use of cookies and custom audience functions in advertising activities',
    'user_privacy.item5.list1.title': 'About the use of cookies',
    'user_privacy.item5.list1.description':
      'We use various Internet advertising services, and we may use your cookies provided by third-party distributors.',
    'user_privacy.item5.list2.title': 'What is a cookie?',
    'user_privacy.item5.list2.description':
      'A cookie is a mechanism that saves the usage history and input contents sent and received between the browser and the server as a file on your computer when you use a web page. The next time you visit the same page, the cookie information will allow the page operator to change the display for each customer. If you allow cookies to be sent and received in your browser settings, the website can retrieve cookies from your browser. To protect your privacy, your browser will only send cookies sent and received by the website\'s server.',
    'user_privacy.item5.list3.title': 'Purpose of use of cookies',
    'user_privacy.item5.list3.description':
      'We use cookies for the following purposes: In addition, we may store and refer to our cookies via third-party distributors based on the consignment of advertisement distribution to third-party distributors.',
    'user_privacy.item5.list4.title':
      'To enable you to provide customized services for each customer by referring to the stored customer registration information when you log in to the authentication service.',
    'user_privacy.item5.list4.1':
      '1. To display the most appropriate advertisement on another company\'s site based on the content that the customer is interested in and the usage status on our site',
    'user_privacy.item5.list4.2': '2. To investigate the number of users and traffic of our site',
    'user_privacy.item5.list4.3': '3. To improve our service',
    'user_privacy.item5.list4.4':
      '4. From the viewpoint of maintaining security, to encourage customers who have passed a certain amount of time to re-enter their password (re-authentication).',
    'user_privacy.item5.list4.5': 'About customer setting of cookies',
    'user_privacy.item5.list4.6':
      'You can select the settings for sending and receiving cookies from "Allow all cookies", "Deny all cookies", "Notify user when cookies are received", and so on. The setting method differs depending on the browser. Please check the "Help" menu of your browser for how to set cookies.',
    'user_privacy.item5.list4.7': 'You can opt out of Google\'s cookie settings by visiting the following page.',
    'user_privacy.item5.list4.8':
      'Please note that if you select the setting to reject all cookies, you may be restricted in using various services on the Internet, such as not being able to receive services that require authentication.',
    'user_privacy.item5.list5.title': 'About using a custom audience',
    'user_privacy.item5.list5.description':
      'Request Facebook and Twitter (hereinafter referred to as "Facebook, etc.") to display customized advertisements for customers based on the usage status of our website, and use such displayed advertisements. For the purpose of understanding the situation, we use the custom audience function.',

    'user_privacy.item5.list6.title': 'What is a custom audience function?',
    'user_privacy.item5.list6.description':
      'It is a function that collates the information of customers registered with us with the information registered with Facebook etc. and delivers our advertisement on the customer\'s page on Facebook etc. for the matching customers. With this function, Facebook etc. will not acquire the personal information of our customers.',

    'user_privacy.item5.list7.title': 'Opt-out of custom audience features on Twitter',
    'user_privacy.item5.list7.description':
      'If you would like to opt out of the custom audience feature on Twitter, please follow the procedure from this URL.',

    'user_privacy.item6.title': 'About withdrawal of consent',
    'user_privacy.item6.description1':
      'If there are any corrections to the registered information registered by the customer,',
    'user_privacy.item6.description2':
      'If you wish to suspend the use of your personal information, please contact our customer service. However, please be aware that once the withdrawal procedure is completed, you will not be able to use some or all of the services provided by our site.',
    'user_privacy.item6.description3': 'Customer service link',

    'user_privacy.item7.title': 'Changes in privacy policy, etc.',
    'user_privacy.item7.description':
      'Due to various circumstances, we may partially change, modify, add, or delete the above privacy policy, but in that case we will notify you on the site. Unless otherwise indicated, these changes will be effective as soon as they are posted on this website.',

    'user_privacy.item8.title': 'Our affiliated companies',
    'user_privacy.item8.description1': 'Affiliated companies are the following companies.',
    'user_privacy.item8.description2': 'WILLER Co., Ltd.',
    'user_privacy.item8.description3': 'WILLER EXPRESS Co., Ltd.',
    'user_privacy.item8.description4': 'WILLER TRAINS Co., Ltd.',
    'user_privacy.item8.description5': 'WILLER Non-life Insurance Service Co., Ltd.',
    'user_privacy.item8.description6': 'Tetsu Fund Co., Ltd.',
    'user_privacy.item8.description7': 'Hotel Operation Systems Co., Ltd. (Hotel Hakuba)',
    'user_privacy.item8.description8': 'WILLER VIETNAM Co., Ltd.',
    'user_privacy.item8.description9': 'WILLER VTI Company Limited',
    'user_privacy.item8.description10': 'Other companies that have a capital tie-up with WILLER Co., Ltd.',
    'user_privacy.item8.description11': 'Other inquiries regarding privacy policy',

    // mobi term of service
    'mobi_term.title': 'mobi Terms of Service',
    'mobi_term.description':
      'WILLER Co., Ltd. (hereinafter referred to as "our company") mainly refers to the "mobi Community Mobility App" (hereinafter referred to as "this application") for the mobi service terms of use (hereinafter referred to as "these terms"). It defines the terms of use of the mobi service operated and provided through.',

    'mobi_term.item1.title': 'Article 1 (General rules)',
    'mobi_term.item1.content1.title':
      'This agreement is an individual agreement in the mobi Community Mobility App Terms of Use (hereinafter referred to as the "original agreement"), and unless otherwise specified regarding the mobi service, this agreement takes precedence.',
    'mobi_term.item1.content2.title':
      'When applying for the mobi service, the user shall be deemed to have agreed to this agreement, the attached procedure manual, usage rules, etc. (hereinafter referred to as "this agreement, etc.").',
    'mobi_term.item1.content3.title':
      'Under the original terms, we will be the provider and the mobi service will be an individual service.',
    'mobi_term.item1.content4.title':
      'The contents of this agreement, etc. may be changed at our discretion without any prior notice to the user in the following cases. In this case, the changed contents of this agreement will be applied to the use after the change date. In addition, when we change the contents of this agreement etc., we shall notify the user of the changed contents in advance within a reasonable period of time.',
    'mobi_term.item1.content4.list1':
      'When the contents of this agreement etc. are changed for the general benefit of the user',
    'mobi_term.item1.content4.list2':
      'Changes to the contents of these Terms, etc. do not violate the purpose of these Terms, etc., and are rational in light of the necessity of the changes, the appropriateness of the changed contents, the contents of the changes, and other circumstances related to the changes. When.',
    'mobi_term.item1.content5.title':
      'Even if some of the provisions of these Terms are invalidated or revoked by law or judgment, the validity, legality and legal force of the remaining provisions shall still be valid. ..',

    'mobi_term.item2.title': 'Article 2 (Contents of mobi service)',
    'mobi_term.item2.content1.title':
      'In the mobi service, users can receive the services specified in each of the following items.',
    'mobi_term.item2.content1.list1':
      'From the virtual boarding / alighting locations set by the Company, general passengers by a passenger car carrier (hereinafter referred to as "transporter") affiliated with the Company in the section of the boarding place and the getting-off place set by this application. Car transportation service (hereinafter referred to as "transportation service")',
    'mobi_term.item2.content1.list2':
      'Transportation service by a carrier affiliated with our company in the section of the boarding place and the getting-off place set through our call center from the virtual boarding / alighting place set by our company.',
    'mobi_term.item2.content1.list3': '30-day flat-rate service for the previous 2 services',
    'mobi_term.item2.content1.list4':
      'Optional service that includes relatives living with the user in the service of the previous issue',
    'mobi_term.item2.content2.title':
      'In the preceding paragraph, the general passenger car transportation contract shall be concluded between the user and the transportation company, and the transportation contract shall be in accordance with the conditions of carriage separately specified by the transportation company.',

    'mobi_term.item3.title': 'Article 3 (Usage fee)',
    'mobi_term.item3.content1.title':
      'Whenever a user applies for the service of Paragraph 1, Item 1 or 2 of the preceding Article (hereinafter referred to as "one-time use"), the amount specified in the usage fee table separately specified is the amount specified in each of the following items. Payment shall be made by the method specified in.',
    'mobi_term.item3.content1.list1':
      'When applying through this application (No. 1): Credit card payment by the payment agent specified in the original agreement or cash payment directly with the carrier when providing the transportation service',
    'mobi_term.item3.content1.list2':
      'When applying through our call center (No. 2): Cash settlement directly with the carrier when providing the transportation service',
    'mobi_term.item3.content2.title':
      'When a user applies for the service specified in Paragraph 1, Item 3 of the preceding Article (hereinafter referred to as "flat-rate usage"), the flat-rate charge specified in the usage charge table can be set to any of the methods specified in each of the following items. Shall be paid by.',
    'mobi_term.item3.content2.list1': 'Credit card payment by payment agent',
    'mobi_term.item3.content2.list2':
      'Transfer to the bank account specified by our company (transfer fee is paid by the user)',
    'mobi_term.item3.content2.list3': 'Account transfer',
    'mobi_term.item3.content3.title':
      ' The payment due date in each item of the preceding paragraph shall be as set forth in each of the following items.',
    'mobi_term.item3.content3.list1':
      'In the case of payment agency, at the time of applying for a 30-day flat-rate service',
    'mobi_term.item3.content3.list2': 'In the case of transfer: The date specified by us in this application',
    'mobi_term.item3.content3.list3':
      'In the case of fund transfer: The date specified by the financial institution that carries out the fund transfer',
    'mobi_term.item3.content4.title':
      'When a user applies for an optional service (hereinafter referred to as "family use") specified in Paragraph 1, Item 4 of the preceding Article, the flat-rate use applied to the user himself / herself depends on the number of people applying the family use. Similarly, the amount specified in the usage fee table shall be added and paid. In this case, the payment method shall be the same as the payment method of the user\'s own usage fee.',
    'mobi_term.item3.content5.title':
      'The usage fee stipulated in Paragraphs 2 and 3 of this Article cannot be refunded for any reason such as whether or not the user has used it, the number of times it has been used, or any other reason.',

    'mobi_term.item4.title': 'Article 4 (Update)',
    'mobi_term.item4.content1.title':
      'For flat-rate usage (including family usage that accompanies it), if the payment method is credit card payment or fund transfer, if there is no request from the user by the last day of flat-rate usage, it will be automatically started from the next day. It is assumed that a new flat-rate usage will start under the same conditions. In this case, credit card payment will be automatically deducted on the new flat-rate usage start date, and direct debit will be automatically deducted on the date specified by the direct debit financial institution.',
    'mobi_term.item4.content2.title':
      'For flat-rate usage (including accompanying family use) whose payment method is bank transfer, if the usage fee is paid by the last day of flat-rate usage, the same conditions will be applied from the day after that day. Flat-rate usage shall start.',
    'mobi_term.item4.content3.title':
      'If it cannot be confirmed that the usage fee has been paid on the business day following the payment date of the new flat-rate usage specified in item 2 above, the Company will use the flat-rate usage even after the start date of this new flat-rate usage. Can be canceled immediately.',

    'mobi_term.item5.title': 'Article 5 (Cancellation of transportation service)',
    'mobi_term.item5.content1.title':
      'Relatives of users and users to whom family use is applied (hereinafter referred to as "users, etc.") after applying for the transportation service by the mobi service until the vehicle by the transportation company arrives at the departure point. In the meantime, you can cancel your application for this shipping service at any time.',
    'mobi_term.item5.content2.title':
      'If we confirm the cancellation of Paragraph 1 of this Article by a one-time user, we will promptly refund the paid usage fee.',

    'mobi_term.item6.title': 'Article 6 (User\'s Obligation)',
    'mobi_term.item6.content1.title':
      'When receiving the transportation service, the user, etc. shall comply with the Road Traffic Act, the contract of carriage stipulated by the transportation company, and the instructions of the crew of the transportation service. In addition, if the crew member of the vehicle providing the operation service reasonably admits that the user, etc. has violated or may violate this Article, the crew member, as the manager of the vehicle, will inform this user, etc. On the other hand, you can immediately refuse to board or order to get off.',
    'mobi_term.item6.content2.title': 'Users, etc. shall not unnecessarily repeat application and cancellation.',
    'mobi_term.item6.content3.title':
      'The mobi service (however, limited to rated use and family use) is available only to the user, etc., and the accompanying person, such as the user, is a separate user, etc. Unless you apply as, you cannot ride with us.',

    'mobi_term.item7.title': 'Article 7 (Response to accidents during transportation services)',
    'mobi_term.item7.content1.title':
      'In the unlikely event that the user, etc. suffers damage due to an accident or trouble (hereinafter referred to as "accident, etc.") that occurs while using the transportation service (including the time during getting on and off), the user, etc. Based on the conditions of carriage established by the carrier, the matter shall be discussed and resolved with the carrier.',
    'mobi_term.item7.content2.title':
      'The Company shall be able to cooperate in the resolution of accidents, etc. at its own discretion, but shall not be liable for any damages caused to the user due to accidents, etc.',
    'mobi_term.item7.content3.title':
      'When a user, etc. and a carrier, etc. file a proceeding to resolve a dispute in this Article, the provisions of Article 11 of the Original Covenant shall not apply, but shall be interpreted based on Japanese law and settled in a court in Japan. increase.',

    'mobi_term.item8.title': 'Article 8 (Information about users, etc.)',
    'mobi_term.item8.content1.title':
      'By applying for the mobi service, users, etc. will receive personal information such as the user\'s name, gender, contact information, etc. to the extent necessary for the purpose of providing the transportation service from WILLERS through this application by the Company and the transportation company. It is assumed that you have received the provision and have agreed to the privacy policy or personal information handling policy set by the Company and the carrier.',
    'mobi_term.item8.content2.title':
      'The Company and the transportation company shall appropriately manage the personal information of users, etc. acquired based on the preceding paragraph or in the process of providing the mobi service, based on the Personal Information Protection Law and related provisions.',
    'mobi_term.item8.content3.title':
      'Regarding the preceding paragraph, the privacy policy or personal information handling policy, etc. established by the Company and the shipping company, and the contact information regarding personal information will be posted on this application.',

    'mobi_term.item9.title': 'Article 9 (Exclusion of antisocial forces)',
    'mobi_term.item9.content1.title':
      'Users, etc. shall make a commitment by stating that they do not fall under any of the following items and that they do not have a close exchange relationship with individuals or groups in each of the following items.',
    'mobi_term.item9.content1.list1': 'Boryokudan',
    'mobi_term.item9.content1.list2':
      'Members of the gangsters (including associate members; the same shall apply hereinafter)',
    'mobi_term.item9.content1.list3':
      'An organization in which a gangster-related company or a gangster or a member of a gangster holds an important position in terms of investment or business execution.',
    'mobi_term.item9.content1.list4': 'Sokaiya, social movement advocate Goro and other antisocial forces',
    'mobi_term.item9.content1.list5':
      'Special intelligence violent groups and other groups that fall under antisocial forces or their members',
    'mobi_term.item9.content1.list6': 'Others equivalent to the previous items',
    'mobi_term.item9.content2.title':
      'The user, etc. shall make a commitment by expressing that he / she will not perform any of the following acts by himself / herself or by using a third party.',
    'mobi_term.item9.content2.list1': 'Violent or unjustified demands beyond legal responsibility',
    'mobi_term.item9.content2.list2': 'Acts of threatening behavior or using violence in relation to transactions',
    'mobi_term.item9.content2.list3':
      'Disseminating rumors, damaging the credibility of the other party or interfering with the other party\'s business by using counterfeiting or power',
    'mobi_term.item9.content2.list4': 'Other acts equivalent to the previous items',
    'mobi_term.item9.content3.title':
      'The Company and the transportation company shall immediately stop providing the mobi service and refuse to provide it if the user, etc. violates or determines that the items stated in the preceding two paragraphs are violated. In these cases, the parties, etc. may suffer all damages caused by the Company and the transportation company due to their own violation (including but not limited to lost profits due to reputational damage, attorney\'s fees, etc.). Shall be reimbursed.',

    'mobi_term.item10.title': 'Article 10 (Mutatis mutandis of the original agreement)',
    'mobi_term.item10.description':
      'The provisions of Articles 4 to 10 of the original agreement shall apply mutatis mutandis to this agreement. The "Company" in the original agreement applied mutatis mutandis shall be WILLER Co., Ltd. specified in this agreement, and the "user" shall be read as the user, etc. specified in this agreement.',

    // Subscription landing page
    'sub.title1': 'Last 30 days',
    'sub.title2': '31 to 60 days prior',
    'sub.title3': '61 to 90 days prior',

    'sub.description1': 'Make your usual travel more convenient and affordable.',
    'sub.description2': 'Subscription plan',

    'sub.mobi_define': 'mobi is',
    'sub.mobi_define1': 'a new form of transportation for everyone.',
    'sub.mobi_define2': 'Subscribe to our service plan',
    'sub.mobi_define3': 'and travel anywhere at anytime you want.',

    'sub.feature': 'Feature',
    'sub.feature_description': 'Feature of subscription plan',
    'sub.feature_description1': '{{amount}} / month',
    'sub.feature_description1.2': '(Discounted price for first 2 months)',
    'sub.feature_description2': 'Unlimited rides',
    'sub.feature_description3': 'Pick-up at your selected location and drop-off',
    'sub.feature_description4': 'at your destination within the service area.',
    'sub.feature_description5': 'Your usage status',

    'sub.scope1_description1': 'Commute conveniently',
    'sub.scope1_description2': '',
    'sub.scope1_description3': 'Pick you up at your selected depature location',
    'sub.scope1_description4': 'and drop you off at your desired location',
    'sub.scope1_description5': 'Select the area you want to use',

    'sub.scope2_description1': 'Place a reservation',
    'sub.scope2_description2': '',
    'sub.scope2_description3': 'mobi run in a circular route in your area',
    'sub.scope2_description4': 'Book a ride with our application and we will be right \n there to pick you up.',

    'sub.scope3_description1': 'Add your family to the subscription plan with an additional fee of {{fee}} for each member',
    'sub.scope3_description2': '',
    'sub.scope3_description3': 'Up to {{member}} family members',
    'sub.scope3_description4': 'can use this plan without limitation',
    'sub.scope3_description5': 'Learn more about Family Option',
    'established_year': 'Established on June 17, 2021 Implemented on June 17, 2021',

    // Family option
    'family_option.title1': 'Travel with family, conveniently and economically',
    'family_option.description': 'Subscription plan',
    'family_option.title2': 'Family option',
    'family_option.introduce': 'Your family can use mobi\'s subscription plan',
    'family_option.introduce1': 'with an additioal fee of',
    'family_option.introduce2': '{{value}} for each member',
    'family_option.feature': 'Feature',
    'family_option.feature_description': 'Feature of Family Option',
    'family_option.feature1_title': 'You can add family members to this plan with an additioal fee of {{value}} for each member',
    'family_option.feature1_title2': '',
    'family_option.feature1_description1': '',
    'family_option.feature1_description2': 'Up to {{value}} family members can use',
    'family_option.feature1_description3': 'subscription plan without any limitation',
    'family_option.feature2_title1': 'Travel in comfort with your family',
    'family_option.feature2_title2': 'by hopping on to mobi!',
    'family_option.feature2_description1': 'Family members can also download and use the app',
    'family_option.feature2_description2': '',
    'family_option.feature2_description3': 'Family members can easily register, be added on to the family option and use mobi.',
    'family_option.feature2_description4': '',
    'family_option.feature2_description5': '',
    'family_option.feature3_title1': 'Even when your family do not use smartphone, you still can book mobi by phone number',
    'family_option.feature3_title2': '',
    'family_option.feature3_description1': 'Operator will arrange your reservation',
    'family_option.feature3_description2': 'and guide you to the boarding place',
    'family_option.feature4_title1': 'Unlimited travel to anywhere',
    'family_option.feature4_title2': 'within out service area',
    'family_option.feature4_description1': 'mobi moves around in your area',
    'family_option.feature4_description2': 'Book a ride with our application and we will be right there to pick you up',
    'family_option.fast_feature': 'Select the area you want to use',
    'family_option.feature5_title1': 'A vehicle will pick you within 10-15 minutes on average!',
    'family_option.feature5_title2': '',
    'family_option.feature5_description1': 'mobi moves around within the designated service area, so a vehicle will be quickly assigned to you once you book a ride with the app!',
    'family_option.feature5_description2': '',
    'family_option.japan_only': 'Japan only',

    // Ticket Landing Page
    'ticket.title1': 'Ride more economical than one time payment!',
    'ticket.title2': 'Tickets subscription plan',
    'ticket.background_title1': 'Tickets subscription plan',
    'ticket.background_title2': 'Tickets subscription plan',
    'ticket.content1': 'mobi is',
    'ticket.content2': 'an on-demand ride sharing service for your affordable and convenient travel in city center areas.',
    'ticket.content3': '',
    'ticket.ticket_price': 'Tickets subscription plan',
    'ticket.ticket_price.content': 'Why subscription plan ?',
    'ticket.save_money.content1': 'Did you know, you enjoy many exclusive perks as a tickets plan subscriber.',
    'ticket.save_money.content2': 'Tickets plan automatically renew every cycle,',
    'ticket.save_money.content3': ' so it is fuss free.',
    'ticket.feature1.content1': 'Better price for your rides',
    'ticket.feature1.content2': 'As a plan subscriber,',
    'ticket.feature1.content3': 'you enjoy lower unit price for each ride.',
    'ticket.feature2.content1': 'Ride with family and friends',
    'ticket.feature2.content2': 'You can apply more than 1 ticket at a time,',
    'ticket.feature2.content3': 'so it’s a great choice for riding with family and friends.',
    'ticket.feature3.content1': 'Unused tickets automatically gets carried forward to the next cycle',
    'ticket.feature3.content2': 'The validity period for your remaining tickets automatically get extended another 30-days, when you remain an active subscriber of the same plan, so you have nothing to lose.',
    'ticket.feature3.content3': '',
    'ticket.footer1': '※ Kindly note that remaining tickets validity period can only be extended once.',
    'ticket.footer2': '※ Kindly note that tickets (top up tickets included) will only be valid for use, when you hold an active subscription plan.',

    // terms_mobi
    'terms.title': 'mobi Community Mobility<br>App Terms of Service',
    'terms.description':
      'mobi Community Mobility The application terms of use (hereinafter referred to as the "Terms") are the application "mobi Community Mobility App" (hereinafter referred to as the "Company") operated and provided by the Singapore corporation WILLERS PTE. LTD. (Hereinafter referred to as the "Company"). Hereinafter referred to as "this application").',

    'terms.item1.title': 'Article 1 (General rules)',
    'terms.item1.content1.title':
      'This application is a person who installed this application on a portable information terminal such as a smartphone (hereinafter referred to as a compatible terminal) owned or managed by himself / herself after agreeing to this agreement (hereinafter referred to as "user"). The use of this application is permitted for.).',
    'terms.item1.content2.title':
      'By using this application or viewing or applying for the individual service specified in Article 2, the user shall be referred to as the "individual agreement" specified in Article 2 in addition to this agreement. ) And the terms and conditions attached to this agreement or individual agreements (hereinafter collectively referred to as "these agreements, etc.") are deemed to have been confirmed and agreed.',
    'terms.item1.content3.title':
      'The contents of this agreement, etc. may be changed without any prior notice to the user in the following cases at the discretion of the Company or the administrator of the individual agreement. In this case, the changed contents of this agreement will be applied to the use after the change date. In addition, when we change the contents of this agreement etc., we shall notify the user of the changed contents in advance within a reasonable period of time.',
    'terms.item1.content3.list1':
      'When the contents of this agreement etc. are changed for the general benefit of the user',
    'terms.item1.content3.list2':
      'Changes to the contents of these Terms, etc. do not violate the purpose of these Terms, etc., and are rational in light of the necessity of the changes, the appropriateness of the changed contents, the contents of the changes, and other circumstances related to the changes. When.',
    'terms.item1.content4.title':
      'Even if some of the provisions of these Terms are invalidated or revoked by law or judgment, the validity, legality and legal force of the remaining provisions shall still be valid. ..',

    'terms.item2.title': 'Article 2 (Services to be provided)',
    'terms.item2.content1.title':
      'In this application, the user can receive the service specified in each of the following items (hereinafter referred to as "this service") free of charge.',
    'terms.item2.content1.list1':
      'Route search: By using the search method provided by this application, you can search and display one route or multiple routes for the optimum route and transportation method from the departure point to the destination in a specific area.',
    'terms.item2.content1.list2':
      'Individual service search: One or more transportation services provided by businesses other than our company (hereinafter referred to as "individual services") related to the route search in the previous issue or as a result of a user search. Is what can be displayed 。',
    'terms.item2.content1.list3':
      'Payment agency: A company that provides individual services through a third-party payment agency when settling expenses incurred through the use of this application (hereinafter referred to as "provider company"). However, we will pay this fee on your behalf. The payment method for individual services shall be determined by the individual terms and conditions.',
    'terms.item2.content2.title':
      'When a user applies for an individual service, the Company will introduce each individual service through this application or, in some cases, act as a payment agent, and the user will conclude a contract for the individual service only with the provider. Suppose.',
    'terms.item2.content3.title':
      'Depending on the location of the user, the portable information terminal used, the communication status and other usage environments, some or all of the functions of this service may not be available.',
    'terms.item2.content4.title':
      'The Company may change, add or abolish all or part of this service or individual service at its own discretion without obtaining the prior consent of the user (hereinafter referred to as "change, etc."). Suppose. The Company is not liable for any damages or disadvantages caused to the user due to changes in this service or individual services.',
    'terms.item2.content5.title':
      'If there are conflicting contents between each individual agreement and this agreement, the provisions of each individual agreement, etc. shall take precedence unless otherwise specified.',

    'terms.item3.title': 'Article 3 (Credit card registration)',
    'terms.item3.content1.title':
      'When registering a credit card for payment agency for individual services, the user shall be able to register only the credit card in the name of the user.',
    'terms.item3.content2.title':
      'The user shall not use another person\'s credit card, enter false credit card information, or perform any other acts that the Company deems inappropriate. The Company shall be able to seek compensation from the user for damages, etc. caused by the user committing these violations.',
    'terms.item3.content3.title':
      'The Company may change the credit card that can be used for this service and individual services at the discretion of the Company or the affiliated payment agency.',
    'terms.item3.content4.title':
      'If the card issuer or payment agent refuses to use the credit card used for payment by the user for some reason, or if it is determined that the credit card is inappropriately used according to the standards prescribed by the Company, the Company will , It is possible to change the payment method and other measures that the Company deems necessary without the consent of the user.',
    'terms.item3.content5.title':
      'If a credit card is used as the payment method for the individual service and the change fee or cancellation fee incurred due to the change or cancellation of the contract contents due to the convenience of the user for such individual service is stipulated in the individual agreement, such change Fees and cancellation fees shall also be settled through the credit card used by the Company.',

    'terms.item4.title': 'Article 4 (How to inform users)',
    'terms.item4.description':
      'If it is necessary for the Company and the provider to inform about the use of this service and individual services, based on their own judgment, notification to this application, notification by e-mail to the registered e-mail address to this application, or self It shall be possible to post on the homepage managed by the company or by any one or more of other appropriate methods.',

    'terms.item5.title': 'Article 5 (Personal information)',
    'terms.item5.description':
      'The Company shall handle the personal information of the user acquired through the provision of this application or this service in accordance with the provisions of our privacy policy and applicable personal information laws and regulations, and the user agrees to this.',

    'terms.item6.title': 'Article 6 (Handling of data)',
    'terms.item6.content1.title':
      'In addition to the provisions of the preceding article, the Company will not access the data registered by the user or disclose it to a third party. However, this does not apply to the cases specified in the following items, and the user shall consent to access or disclosure to a third party due to these circumstances.',
    'terms.item6.content1.list1':
      'When repair work is performed when the user\'s data is damaged due to a failure of this application, etc.',
    'terms.item6.content1.list2':
      'When performing the conversion work necessary to reflect the changed, expanded or modified functions of this system in the user\'s data due to the modification to respond to the change, extension or revision of laws and regulations of this application.',
    'terms.item6.content1.list3':
      'When performing necessary data analysis after performing anonymous processing to improve the quality of this application',
    'terms.item6.content1.list4':
      'When it can be reasonably determined that it is necessary to protect the important interests of public life, health, property, etc.',
    'terms.item6.content1.list5': 'When there is a disclosure request based on laws and regulations',
    'terms.item6.content1.list6':
      'When the disclosure destination and the purpose of disclosure are presented and individual consent is obtained from the user.',

    'terms.item7.title': 'Article 7 (Prohibited matters)',
    'terms.item7.content1.title':
      'When using this service, the user shall not perform any act that falls under or is deemed to fall under any of the following items.',
    'terms.item7.content1.list1': 'Violation of this agreement, etc.',
    'terms.item7.content1.list2':
      'To use this service for purposes other than the purpose for which the user himself / herself uses it.',
    'terms.item7.content1.list3':
      'Use the information obtained through the use of this service and individual services for commercial purposes.',
    'terms.item7.content1.list4':
      'The contractual status regarding the use of this service and individual services, or the rights or obligations arising from this contract, can be obtained by a third party (a person who receives individual services at the same time as the user) without the prior consent of the Company or the provider. Transfer to (excluding), transfer, lend, or provide as collateral',
    'terms.item7.content1.list5': 'Registering false information when using this service',
    'terms.item7.content1.list6':
      'Infringement of copyrights, trademark rights, design rights, patent rights and other intellectual property rights of the Company and its providers',
    'terms.item7.content2.title':
      'When using this application, the user shall not perform any act that falls under or is deemed to fall under any of the following items.',
    'terms.item7.content2.list1':
      'Transmission or use of any harmful programs or data including computer viruses to this application',
    'terms.item7.content2.list2':
      'Use of this application in a way that intentionally infringes the intellectual property of a third party',
    'terms.item7.content2.list3':
      'Beyond the scope permitted by this Agreement, you or a third party may register information, change settings, or modify, modify, duplicate, disassemble, or reverse this system. Compile and / or reverse engineer.',
    'terms.item7.content2.list4': 'Other acts that the Company deems inappropriate',
    'terms.item7.content3.title':
      'If we confirm that the user has performed the acts specified in each item of the preceding two paragraphs, or if we reasonably admit that such acts have been performed, we will suspend the access of the user at any time and provide individual services. You may cancel or refuse the use of.',

    'terms.item8.title': 'Article 8 (Interruption, suspension, etc. of this application)',
    'terms.item8.description':
      'The Company will temporarily suspend or suspend the use of all or part of this application after notifying the user in advance without any responsibility if it falls under any of the reasons specified in the following items. Suppose you can. However, in the case of an emergency, prior notice may be omitted.',
    'terms.item8.content1.list1': '設備又はソフトウェアの保守を定期的又は緊急に実施する場合',
    'terms.item8.content1.list2':
      'In the event of suspension or failure of service supply due to our contracted cloud server, communication line, electric power or other public service provider\'s own judgment or force majeure',
    'terms.item8.content1.list3':
      'When there is an order to suspend business or its guidance or request by an administrative agency or a judicial agency due to reasons that cannot be attributed to the Company',
    'terms.item8.content1.list4':
      'When a problem (including errors and bugs) on this application or a problem with the equipment for this system occurs',
    'terms.item8.content1.list5':
      'When the provision of this system is hindered by unauthorized or incorrect operation by the user',
    'terms.item8.content1.list6':
      'When there is unauthorized access from a third party to this application or the equipment for this application',
    'terms.item8.content1.list7':
      'When it becomes necessary to restart this application or the equipment for this application',
    'terms.item8.content1.list8':
      'We have determined that it is necessary to provide a stable usage environment for all users of this application in cases such as when access to this application increases significantly and an excessive load is applied to the operation of this service. if you did this',
    'terms.item8.content1.list9': 'When there is an act corresponding to "prohibited matters"',
    'terms.item8.content1.list10':
      'In addition, when we judge that maintenance interruption is necessary for the operation of this service',

    'terms.item9.title': 'Article 9 (Our responsibility)',
    'terms.item9.content1.title':
      'The Company shall endeavor to be the latest and genuine information regarding the data, map information, GPS coordinate information, etc. posted in this application and individual services, but with the latest and accurate information at the time of use by the user. We do not guarantee that there is.',
    'terms.item9.content2.title':
      'Even if this application links to the site of the provider, etc., we are not responsible for such site. In addition, we shall not be liable for any damages caused by or in connection with the use of the site.',
    'terms.item9.content3.title':
      'If the user causes damage to a third party including the provider through the use of this application, the user shall resolve it at his / her own risk and expense, and the Company shall not be liable at all. ..',
    'terms.item9.content4.title':
      'The Company shall not be liable for any damages caused by the inability to use this service due to the portable information terminal and communication line for using this application.',
    'terms.item9.content5.title':
      'Regarding this agreement, if the Company compensates the user for damages, the amount will be the total amount of the amount settled by the agency settlement of this application in the last 3 months when the damage occurred or the agency settlement of this application in the last 1 month. The upper limit is the higher of the amount settled by 3 times the amount settled by. However, this does not apply if the damage is caused by our intentional or gross negligence.',

    'terms.item10.title': 'Article 10 (User Responsibility)',
    'terms.item10.content1.title':
      'In order to use this application, the user shall prepare a portable information terminal and communication environment and maintain the usable state at the user\'s own responsibility and expense.',
    'terms.item10.content2.title':
      'The user shall manage the access right to this application at the user\'s own risk. In addition, if this service or individual service is used with the user\'s access right, even if it is used by a third party other than the user, the Company can consider it to be used by the user. Suppose.',
    'terms.item10.content3.title':
      'The user shall be liable for any damages caused to the Company or the provider in violation of these Terms and Conditions.',

    'terms.item11.title': 'Article 11 (Consultation Counter / Governing Law)',
    'terms.item11.content1.title':
      'This agreement and any accompanying rules, etc. shall be construed by Singapore law.',
    'terms.item11.content2.title':
      'If there is any doubt about the operation or interpretation of this agreement, etc. in the use of this application, this service and individual services, the user can make inquiries to the following contact information.' +
      '<br>Email address：maas@willer.co.jp',
    'terms.item11.content3.title':
      'For disputes concerning this agreement, the court having jurisdiction over the registered address of the Company in a civil lawsuit in the Republic of Singapore shall be the exclusive agreement jurisdictional court of the first instance. However, if applicable compulsory law allows a lawsuit to be filed in another court, that provision shall apply.',

    // Verify mobile account
    'verify_mobile_account.register_email': 'Registered email address',

    // Verify subscription
    'verify_subcription.family_option_app': 'Register Family Option',
    'verify_subcription.family_option_complete': 'Family Option registered successfully.',
    'verify_subcription.failed_apply_family_option': 'Failed to register Family Option.',

    // Mobile reissue password
    'mobile_reissue_pass.enter_password': 'Please enter your new password',
    'mobile_reissue_pass.enter_pass_confirm': 'Please re-enter your new password',
    'mobile_reissue_pass.re_enter_password': 'Re-enter password',
    'mobile_reissue_pass.enter_password_again': 'Please enter your password again',
    'mobile_reissue_pass.enter_content': 'Password does not match',
    'mobile_reissue_pass.token_failed': 'Check for token failure',
    'validation.required.password': 'Please enter the password',
    'validation.required.re_enter_password': 'Please re-enter the password',

    // Mobile repassword complete
    'mobile_repass_complete.new_password': 'New password',
    'mobile_repass_complete.set': 'has been set',
    'mobile_repass_complete.launch_app': 'Launch the app',
    'mobile_repass_complete.login': 'Please login',

    // tutorial app
    'tutorial_app.title': 'Change your everyday commute',
    'tutorial_app.description': 'Subscription plan',
    'tutorial_app.content1': 'mobi is a new form of',
    'tutorial_app.content2': 'community mobility for everyone.',
    'tutorial_app.content3': 'Place a booking for mobi and enjoy a reasonably priced ride!',
    'tutorial_app.content4': '',
    'tutorial_app.feature.title': 'Features',
    'tutorial_app.feature.description': 'Features of mobi',
    'tutorial_app.location.title': 'Commute conveniently',
    'tutorial_app.location.content1': 'mobi picks you up and drops you off',
    'tutorial_app.location.content2': 'at your desired locations',
    'tutorial_app.car.title': 'Vehicles move around the service area.',
    'tutorial_app.car.content1': 'Book a ride, we will be there to pick you up',
    'tutorial_app.car.content2': '',
    'tutorial_app.wallet.title': 'Budget friendly',
    'tutorial_app.wallet.content1': 'Sharing transportation with other people in your area makes your commute more affordable and budget friendly',
    'tutorial_app.wallet.content2': '',
    'tutorial_app.wallet.content3': '',
    'tutorial_app.request.title': 'Book a ride',
    'tutorial_app.request.description': 'How to book',
    'tutorial_app.step1.title': 'Step.1',
    'tutorial_app.step1.content': 'Tap on your preferred pick-up and drop-off locations',
    'tutorial_app.step2.title': 'Step.2',
    'tutorial_app.step2.content': 'Select mobi from the list',
    'tutorial_app.step3.title': 'Step.3',
    'tutorial_app.step3.content': 'Get on the vehicle at your selected location',
  },
};

export default en;
