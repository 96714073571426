import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Box, Container, Grid} from '@material-ui/core';

// eslint-disable-next-line require-jsdoc
class Index extends Component {
  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    return (
      <Container maxWidth="xs" style={{height: '100vh'}} className="landing-page">
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container>
            <Grid container className="header-background" style={{height: '250px', width: '375px'}}>
              <Grid className="text-background" style={{display: 'block'}}>
                <div>
                  <img src={`${process.env.PUBLIC_URL}/images/logo_mobi1.svg`} alt="Logo" style={{width: 115}} />
                </div>
                <span className="font-weight-400">{t('ticket.background_title1')}</span>
                <Box className=" font-weight-400 text-background-bottom" style={{margin: 'auto'}}>
                  {t('ticket.background_title2')}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
            <img src={`${process.env.PUBLIC_URL}/images/logo_mobi2.svg`} alt="Logo" style={{width: 150}} />
            <span className="font-24 font-weight-600 mt-5 item-content">{t('ticket.title2')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="list-content">
            <span className="font-14 item-content ml-24 mr-24 mt-20 font-weight-600">{t('ticket.content1')}</span>
            <div className="font-14 item-content ml-24 mr-24 mt-5 font-weight-600">{t('ticket.content2')}</div>
            {this.props.i18n.language === 'ja' && (
              <>
                <div className="font-14 item-content mt-5 font-weight-600">{t('ticket.content_ja1')}</div>
                <div className="font-14 item-content mt-5 font-weight-600">{t('ticket.content_ja2')}</div>
              </>
            )}
            <span className="font-14 item-content ml-24 mr-24 mt-5 font-weight-600">{t('ticket.content3')}</span>
          </Grid>
        </Grid>
        <Grid container className="mt-60 background-image">
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className="font-24 font-weight-600">{t('ticket.ticket_price')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8">
            <span className="font-14">{t('ticket.ticket_price.content')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-20 font-weight-600 item-content">{t('ticket.save_money.content1')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              {this.props.i18n.language === 'ja' ? (
                <span className="font-14 mr-24 ml-24 text_align_center item-content">
                  {t('ticket.save_money.content2')}
                </span>
              ) : (
                <>
                  <span className="font-14 text_align_center item-content">{t('ticket.save_money.content2')}</span>
                  <span className="font-14 text_align_center item-content">{t('ticket.save_money.content3')}</span>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/wallet_logo.png`}
                alt="wallet logo"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-20 font-weight-600 item-content">{t('ticket.feature1.content1')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14  text_align_center item-content">{t('ticket.feature1.content2')}</span>
              <span className="font-14  text_align_center item-content">{t('ticket.feature1.content3')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img src={`${process.env.PUBLIC_URL}/images/passenger.svg`} alt="ticket logo" style={{width: 70}} />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-20 font-weight-600 item-content ml-24 mr-24">{t('ticket.feature2.content1')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('ticket.feature2.content2')}</span>
              <span className="font-14 item-content">{t('ticket.feature2.content3')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/wallet_logo.png`}
                alt="people logo"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-20 font-weight-600 item-content ml-24 mr-24">{t('ticket.feature3.content1')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content ml-24 mr-24">{t('ticket.feature3.content2')}</span>
              <span className="font-14 item-content ml-24 mr-24">{t('ticket.feature3.content3')}</span>
            </Grid>
          </Grid>
          <Grid item className="mt-24 margin-item-0 width_100">
            <span className="font-14 item-content">{t('ticket.footer1')}</span>
            <span className="font-14 item-content mt-12">{t('ticket.footer2')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          ></Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 mb-75 margin-item-0 width_100 background-white"
          ></Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation('translations')(Index);
