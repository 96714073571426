/* eslint-disable max-len */
const vi = {
  translations: {
    // Route
    'route.home': 'Trang chủ',
    'route.login': 'Đăng nhập',
    'route.registration': 'Đăng ký',
    'route.logout': 'Đăng xuất',
    'route.verify_mobile_account': 'Xác nhận tài khoản mobile',
    'route.mobile_reissue_password': 'Cấp lại mật khẩu',

    // Button common
    'common.btnAgree': 'Đồng ý',
    'common.btnAdd': 'Thêm',
    'common.btnUpdate': 'Cập nhật',
    'common.btnDelete': 'Xóa',
    'common.btnSend': 'Gửi',
    'common.btnChange': 'Thay đổi',
    'common.btnLogin': 'Đăng nhập',
    'common.btnLogout': 'Đăng xuất',
    'common.btnRegister': 'Đăng ký',
    'common.btnCreate': 'Tạo',
    'common.btnReturn': 'Trở về',
    'common.btnCancel': 'Hủy',
    'common.btnResetSearch': 'Đặt lại điều kiện tìm kiếm',
    'common.btnSearch': 'Tìm kiếm',
    'common.btnReopen.Stop': 'Mở lại/Dừng',
    'common.btnUploadCsv': 'Xuất CSV',
    'common.btnDetail': 'Chi tiết',
    'common.btnClose': 'Đóng',
    'common.btnBulkRegistration': 'Đăng ký tâp thể',
    'common.ja': 'Tiếng Nhật',
    'common.en': 'Tiếng Anh',
    'common.vi': 'Tiếng Việt',

    // Common message code
    'messageCode.updateConfirm': 'Bạn có muốn cập nhât {{field}} này?',
    'messageCode.deleteConfirm': 'Bạn có muốn xóa {{field}} này?',
    'messageCode.changePasswordConfirm': 'Bạn có muốn cập nhạt mật khẩu không?',
    'messageCode.accountInviteConfirm': 'Bạn có muốn gửi một email?',
    'messageCode.deleteConfirmProduct': 'Do you want to delete the product?',
    'messageCode.deleteConfirmSupplier': 'Do you want to delete the supplier?',
    'messageCode.deleteConfirmDriver': 'Do you want to delete the driver?',
    'messageCode.deleteConfirmImageMaster': 'Do you want to delete the image master?',
    'messageCode.updateConfirmDriver': 'Do you want to update the driver?',
    'messageCode.updateConfirmVehicle': 'Do you want to update the vehicle?',
    'messageCode.deleteConfirmVehicle': 'Do you want to delete the vehicle?',
    'messageCode.verifyEmailToken': 'The email address you registered has been successfully authenticated.',

    // Common error field message
    'errorFields.invalidDateMessage': 'Invalid Date Format',
    'errorFields.maxDateMessage': 'Date should not be after maximal date',
    'errorFields.minDateMessage': 'Date should not be before minimal date',
    'errorFields.passwordRequired': 'Hãy nhập mật khẩu',
    'errorFields.emailRequired': 'Please enter the Email.',
    'errorFields.emailFormat': 'Invalid email address format.',
    'errorFields.textLength': 'Please enter within 128 characters',
    'errorFields.text256Length': 'Please enter within 256 characters',
    'errorFields.cannotChange': 'Can not change',
    'errorFields.birthdayRequired': 'Please enter your Birthday.',
    'errorFields.accountNameRequired': 'Please enter your Account Name.',
    'errorFields.rePasswordRequired': 'Please enter the Re-enter Password.',
    'errorFields.phoneNumberRequired': 'Please enter your Phone Number.',
    'errorFields.confirmPasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.oldPasswordRequired': 'Please enter your current password.',
    'errorFields.newPasswordRequired': 'Please enter a new password.',
    'errorFields.passwordMin': 'Please enter at least 6 characters including lowercase letters.',
    'errorFields.confirmPasswordRequired': 'Please enter the password again.',
    'errorFields.personChargeRequired': 'Please enter the Person in charge.',
    'errorFields.firstNameRequired': 'Please enter the First Name.',
    'errorFields.lastNameRequired': 'Please enter the Last Name.',
    'errorFields.addressRequired': 'Please enter the Address.',
    'errorFields.rePasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.businessRequired': 'Please enter the Business name.',
    'errorFields.mobileRequired': 'Please enter the phone number.',
    'errorFields.checkPhoneNumberMatch': 'The phone number do not match. Please try again.',
    'errorFields.sizeFile': 'Image size must be greater than 200KB and not exceed 500KB.',
    'errorFields.statusRequired': 'Please choose the status.',
    'errorFields.typeRequired': 'Please choose the type.',
    'errorFields.industryRequired': 'Please choose the industry.',
    'errorFields.countryRequired': 'Please choose the country.',
    'errorFields.supplierNameRequired': 'Please choose the supplier name.',
    'errorFields.supplierCodeRequired': 'Please choose the supplier code.',
    'errorFields.supplierAddressRequired': 'Please choose the address.',
    'errorFields.facilityNameRequired': 'Please enter the facility name.',
    'errorFields.areaRequired': 'Please choose the area.',
    'errorFields.languageRequired': 'Please choose the language.',
    'errorFields.holidayRequired': 'Please choose the holiday.',
    'errorFields.feeRequired': 'Please enter the fee.',
    'errorFields.feeTypeRequired': 'Please choose the type fee.',
    'errorFields.errorPrice': 'Please enter the charges.',

    // Message error call API
    'error.file.size.less.than.200KB': 'File size not less than 200kb',
    'error.file.size.is.empty': 'The file cannot be left blank',
    'error.no.record': 'No records',
    'error.already.exist.or.has.been.invited': 'Email already exist or has been invited.',
    'error.account.user.not.found': 'Account user not found.',
    'error.business.not.found': 'Business not found.',
    'error.role.not.found': 'Role not found.',
    'error.permission.not.found': 'Permission not found.',
    'error.api.role.permission.unknown': 'Api role-permission unknown.',
    'error.api.token.not.match': 'Api token not match.',
    'error.link.not.found': 'Link not found.',
    'error.user.not.resumed': 'Account user not resumed.',
    'error.link.expired': 'Link expired.',
    'error.email.not.found': 'Email not found.',
    'error.email.password.invalid': 'Password invalid.',
    'error.change.password': 'Current password is incorrect.',
    'error.newPassword.must.not.equals.currentPassword': 'The current password and new password must not match.',
    'error.not.found.user': 'User not found.',
    'error.user.not.exists': 'User does not exist',
    'error.email.exists': 'Mail does not exist',
    'error.mobile.exists': 'Mobile does not exist',
    'error.reissue.password.token_expired': 'Token already exists',
    'error.reissue.password.token_not_match': 'Bad token format',
    'error.member.not.exists': 'Member does not exist',
    'invalid.email': 'Invalid email',
    'invalid.timeTo': 'Invalid time to',
    'invalid.timeFrom': 'Invalid time from',
    'error.time.from.not.after.time.to': 'The start time is less than the end time',
    'user.email.token.invalid': 'Xác thực email thất bại.\n' +
                                'Vui lòng kiểm tra đường dẫn có bị xuống dòng hay ngắt quãng hay không và thử lại.',
    'error.check.value.empty': 'Please check the values ​​to be entered',

    // Message success call API
    'api.invite.success': 'Invited accounts successfully.',
    'api.active.account.success': 'Active account successfully.',
    'api.add.supplier.success': 'Add supplier successfully.',
    'api.deleted.supplier.success': 'Delete supplier successfully',
    'api.delete.driver.success': 'Delete driver successfully',
    'api.delete.caution.success': 'Delete item successfully',
    'api.update.driver.success': 'Update driver successfully',
    'api.update.caution.success': 'Update item successfully',
    'api.create.driver.success': 'Create driver successfully',
    'api.updated.supplier.success': 'Updated supplier successfully.',
    'api.updated.account.success': 'Updated account successfully.',
    'api.execute.reissue.success': 'Password reissue successfully.',
    'Api.success.change.password': 'Change password successfully.',
    'api.updated.member.success': 'Updated member successfully.',
    'api.created.member.success': 'Created member successfully',
    'api.reissue_password.request.success': 'Request reissue password successfully',
    'api.reissue_password.verify.success': 'Verify reissue password successfully',
    'api.reissue_password.confirm.success': 'Confirm reissue password successfully',
    'api.canceled.member.success': 'Cancelled member successfully',
    'api.deleted.facility.success': 'Delete facilities successfully',
    'api.updated.facility.success': 'Updated facility successfully.',
    'api.add.facility.success': 'Add facility successfully.',
    'api.facility.not.found': 'API facility not found',
    'api.delete.vehicle.success': 'Delete supplier\'s vehicle successfully.',
    'api.updated.vehicle.success': 'Updated supplier\'s vehicle successfully',
    'api.created.vehicle.success': 'Created supplier\'s vehicle successfully',
    'api.update.calendar.success': 'Updated calendar successfully.',
    'api.create.image.success': 'Create image successfully',
    'api.update.image.success': 'Update image successfully',
    'api.delete.image.success': 'Delete image successfully',
    'user.verify.email.token.success': 'Đã xác thực thành công',
    'user.verify.email.token.success2': 'Xác thực địa chỉ email đăng ký thành công',

    // Home page
    'homePage.title': 'Home',

    // Registration page
    'registration.title': 'Account creation',
    'registration.accountType': 'Account type',
    'registration.authority': 'Authority',
    'registration.businessName': 'Business name',
    'registration.email': 'Email',
    'registration.contactName': 'Contact name',
    'registration.password': 'Mật khẩu',
    'registration.rePassword': 'Re-enter password',
    'registration.phoneNumber': 'Phone number',

    // Error page UI
    'maintenance': 'We are currently down for maintenance.',
    'unauthorized': 'Whoops!!!  Access to this resource is denied',

    // validation
    'validation.required': 'Vui lòng nhập {{ field }}',
    'validation.email': 'Định dạng email không hợp lệ.',
    'validation.required.choose': 'Vui lòng chọn {{ field }}',
    'validation.numeric': '{{ field }} phải là một số.',
    'validation.length': 'Nhập {{ field }} với {{ number }} ký tự hoặc ít hơn.',
    'validation.between': 'Độ dài {{field}} phải lớn hơn hoặc bằng {{min}} và nhỏ hơn hoặc bằng {{max}}',

    // common constant
    'currencyCode.VND': '₫',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': '￥',

    // Jit landing page
    'jit.title': 'Dịch vụ mobi chạy cố định',
    'jit.description1': 'Kết nối với các địa điểm bạn thường xuyên đến mọi lúc, mọi nơi!',
    'jit.description2': '',

    'jit.item1.title': 'Đi làm, đi học',
    'jit.item1.description1': 'Dịch vụ đặt trước chuyến đi được chạy theo giờ cố định sẽ giúp bạn tránh được các vấn đề phát sinh khi di chuyển.',
    'jit.item1.description2': '',
    'jit.item1.description3': '',

    'jit.item2.title': 'Đi lại hàng ngày',
    'jit.item2.description1': 'Xe đón và trả theo giờ cố định nên rất tiện lợi khi di chuyển thường xuyên như đi mua sắm, khám bệnh, tập gym v.v..',
    'jit.item2.description2': '',
    'jit.item2.description3': '',

    'jit.item3.title1': 'Đi xa hơn',
    'jit.item3.title2': '',
    'jit.item3.description1': 'Tiện lợi ngay cả khi di chuyển từ khu vực bạn sinh sống đến những khu vực khác xa hơn mà không cần đổi xe.',
    'jit.item3.description2': '',
    'jit.item3.description3': '',

    'jit.btnMethod': 'Cách đặt chuyến xe',
    'jit.btnRequest': 'Đặt chuyến',

    'jit.category1.title': 'Chọn tuyến và thời gian',
    'jit.category1.description1': 'Thời gian xuất phát và đến nơi dự kiến sẽ thay đổi tùy theo tình trạng đặt chỗ của khách hàng.',
    'jit.category1.description2': '',
    'jit.category1.departure': 'Đón',
    'jit.category1.indication': 'Dự kiến',
    'jit.category1.arrival': 'Trả',
    'jit.category1.deadline': 'Hạn cuối đặt chuyến',
    'jit.category1.seats': 'Số ghế trống',
    'jit.category1.thursday': 'Thứ Năm',
    'jit.category1.day': 'Ngày',
    'jit.category1.month': 'Tháng',

    'jit.category2.title': 'Xác nhận số người',
    'jit.category2.description1': 'Bạn có thể sử dụng dịch vụ mobi bằng cách mua gói dịch vụ đi không giới hạn hoặc trả tiền một lần theo lượt.',
    'jit.category2.description2': '',

    'jit.category3.title': 'Thông báo xác nhận đặt chuyến',
    'jit.category3.description1': 'Khi kết thúc thời hạn đặt chuyến, hệ thống sẽ gửi thông báo chi tiết tới bạn về thời gian đón/ trả.',
    'jit.category3.description2': '',
    'jit.category3.description2.1': '',
    'jit.notify': '',

    // User Privacy
    'user_privacy.title1': 'Khi sử dụng trang web ứng dụng WILLERS',
    'user_privacy.title2': 'Chính sách bảo mật',
    'user_privacy.description1':
      'WILLERSPTE.LTD.Vì vậy, chúng tôi coi việc bảo vệ thông tin cá nhân của khách hàng là một trách nhiệm xã hội rất quan trọng trong việc thực hiện các dịch vụ liên quan đến kinh doanh lữ hành, là hoạt động kinh doanh chính của chúng tôi và doanh nghiệp liên kết với họ, và chúng tôi tôn trọng những điều này và xử lý chúng một cách thích hợp. mục đích của việc làm như vậy, chúng tôi đã thiết lập chính sách bảo mật sau đây và đang tham gia vào hoạt động kinh doanh.',
    'user_privacy.description2':
      'Chính sách bảo mật này giải thích việc xử lý thông tin cá nhân của khách hàng mà chúng tôi giữ cho bạn. Vui lòng đọc kỹ chính sách bảo mật này trước khi sử dụng dịch vụ và sản phẩm của chúng tôi. Khi bạn sử dụng các dịch vụ và sản phẩm do công ty chúng tôi cung cấp, chúng tôi coi như bạn đã hoàn toàn hiểu và đồng ý với các nội dung của chính sách bảo mật này.',

    'user_privacy.item1.title': 'Về phạm vi của chính sách bảo mật',
    'user_privacy.item1.description1':
      'Chính sách bảo mật áp dụng cho tất cả thông tin cá nhân được sử dụng trong hoạt động kinh doanh của chúng tôi, bao gồm thông tin cá nhân bạn nhập khi sử dụng dịch vụ và sản phẩm của chúng tôi.',
    'user_privacy.item1.description2': 'Về cung cấp thông tin tự nguyện',
    'user_privacy.item1.description3':
      'Một số thông tin mà chúng tôi lấy được có thể từ chối theo ý muốn của khách hàng, một số thông tin phải cung cấp tùy theo mục đích sử dụng như tên và địa chỉ tại thời điểm đăng ký / giữ chỗ thành viên. Nếu bạn không muốn chúng tôi cung cấp thông tin cụ thể cho bạn, vui lòng không cung cấp cho chúng tôi. Trong trường hợp này, các dịch vụ bạn nhận được có thể bị ảnh hưởng, chẳng hạn như không thể sử dụng tất cả hoặc một phần dịch vụ của chúng tôi, hoặc không thể sử dụng tất cả hoặc một phần các chức năng của trang web của chúng tôi.',
    'user_privacy.item1.description4':
      'Khi bạn cung cấp thông tin cá nhân cho chúng tôi, vui lòng xác định trước liệu bạn có đồng ý với việc mua và sử dụng thông tin cá nhân theo cách hiển thị trên trang web của chúng tôi hay không. Bạn có thể rút lại sự đồng ý của mình bất cứ lúc nào. Tuy nhiên, việc rút lại sự đồng ý không ảnh hưởng đến tính hợp pháp của quá trình xử lý do chúng tôi thực hiện trước khi rút lại.',

    'user_privacy.item2.title': 'Xử lý thông tin cá nhân',
    'user_privacy.item2.description':
      'Chúng tôi sẽ lưu trữ bất kỳ thông tin cá nhân nào có thể lấy được từ bạn trên trang web này dưới sự kiểm soát chặt chẽ, chỉ sử dụng thông tin đó cho mục đích sử dụng được mô tả sau và trừ khi thuộc bất kỳ điều nào sau đây, thông tin cá nhân của bạn sẽ không được tiết lộ hoặc cung cấp cho các bên thứ ba. Ngoài ra, nếu chúng tôi cung cấp nó cho các công ty liên kết, nhà cung cấp dịch vụ, đối tác kinh doanh, v.v. trong phạm vi cần thiết cho mục đích, chúng tôi sẽ không tiết lộ hoặc cung cấp cho bên thứ ba. Các đối tác kinh doanh có nghĩa vụ theo hợp đồng quản lý thông tin cá nhân theo cách tương tự như các tiêu chuẩn do Công ty đặt ra.',
    'user_privacy.item2.list1': 'Được sự đồng ý của khách hàng',
    'user_privacy.item2.list2': 'Khi được yêu cầu tiết lộ hoặc cung cấp dựa trên luật pháp Singapore',
    'user_privacy.item2.list3':
      'Khi cần thiết cho việc bảo vệ tính mạng con người, thân thể hoặc tài sản và khó được sự đồng ý của khách hàng.',
    'user_privacy.item2.list4':
      'Khi chính phủ quốc gia hoặc các cơ quan công quyền địa phương cần hợp tác thực hiện các công việc công và có nguy cơ là việc nhận được sự đồng ý của khách hàng sẽ cản trở việc thực hiện công việc.',
    'user_privacy.item2.list5': 'Khi tiết lộ hoặc cung cấp dữ liệu thống kê trong tình trạng không thể xác định được người đó',

    'user_privacy.item3.title': 'Về thông tin mà chúng tôi có thể có được',
    'user_privacy.item3.list1.1': 'Thông tin về đăng ký thành viên, đặt chỗ',
    'user_privacy.item3.list1.2': 'Tên, địa chỉ, số điện thoại, địa chỉ email, thông tin thanh toán thẻ tín dụng, v.v.',
    'user_privacy.item3.list2': 'Bảng câu hỏi',
    'user_privacy.item3.list3.1': 'Động lực mua hàng, sở thích, v.v.',
    'user_privacy.item3.list3.2': 'Thông tin truy cập ứng dụng và web chung',
    'user_privacy.item3.list3.3': 'Tên miền, thời gian truy cập, loại trình duyệt, loại thiết bị truy cập, lịch sử sử dụng, v.v.',

    'user_privacy.item4.title': 'Về mục đích sử dụng',
    'user_privacy.item4.description':
      'Chúng tôi sẽ sử dụng thông tin cá nhân được khách hàng ủy thác cho chúng tôi trên trang web này cho các mục đích sau.',
    'user_privacy.item4.list1': 'Cung cấp và sắp xếp dịch vụ bạn đã đăng ký và công việc quản lý',
    'user_privacy.item4.list2': 'Gửi thông báo qua thư và e-mail về các vấn đề đã in và các tài liệu liên lạc khác từ công ty của chúng tôi',
    'user_privacy.item4.list3':
      'Phân tích và xem xét thống kê để cải thiện trang web, sản phẩm và dịch vụ của chúng tôi đối với thông tin không nhận dạng cá nhân',
    'user_privacy.item4.list4': 'Hoạt động quảng cáo',

    'user_privacy.item5.title': 'Giới thiệu về việc sử dụng cookie và các chức năng đối tượng tùy chỉnh trong các hoạt động quảng cáo',
    'user_privacy.item5.list1.title': 'Về việc sử dụng cookie',
    'user_privacy.item5.list1.description':
      'Chúng tôi sử dụng các dịch vụ quảng cáo Internet khác nhau và chúng tôi có thể sử dụng cookie của bạn do các nhà phân phối bên thứ ba cung cấp.',
    'user_privacy.item5.list2.title': 'Cookie là gì?',
    'user_privacy.item5.list2.description':
      'Cookie là một cơ chế lưu lịch sử sử dụng và nội dung đầu vào được gửi và nhận giữa trình duyệt và máy chủ dưới dạng tệp trên máy tính của bạn khi bạn sử dụng một trang web. Lần tiếp theo khi bạn truy cập cùng một trang, thông tin cookie sẽ cho phép người điều hành trang thay đổi cách hiển thị cho từng khách hàng. Nếu bạn cho phép gửi và nhận cookie trong cài đặt trình duyệt của mình, trang web có thể truy xuất cookie từ trình duyệt của bạn. Để bảo vệ quyền riêng tư của bạn, trình duyệt của bạn sẽ chỉ gửi cookie do máy chủ của trang web gửi và nhận.',
    'user_privacy.item5.list3.title': 'Mục đích sử dụng cookie',
    'user_privacy.item5.list3.description':
      'Chúng tôi sử dụng cookie cho các mục đích sau: Ngoài ra, chúng tôi có thể lưu trữ và tham chiếu đến cookie của chúng tôi thông qua các nhà phân phối bên thứ ba dựa trên việc ủy ​​thác phân phối quảng cáo cho các nhà phân phối bên thứ ba.',
    'user_privacy.item5.list4.title':
      'Để cho phép bạn cung cấp các dịch vụ tùy chỉnh cho từng khách hàng bằng cách tham khảo thông tin đăng ký khách hàng được lưu trữ khi bạn đăng nhập vào dịch vụ xác thực.',
    'user_privacy.item5.list4.1':
      '1. Để hiển thị quảng cáo phù hợp nhất trên trang của công ty khác dựa trên nội dung mà khách hàng quan tâm và tình trạng sử dụng trên trang của chúng tôi',
    'user_privacy.item5.list4.2': '2. Để điều tra số lượng người dùng và lưu lượng truy cập của trang web của chúng tôi',
    'user_privacy.item5.list4.3': '3. Để cải thiện dịch vụ của chúng tôi',
    'user_privacy.item5.list4.4':
      '4. Trên quan điểm duy trì bảo mật, khuyến khích khách hàng đã qua một khoảng thời gian nhất định nhập lại mật khẩu (xác thực lại).',
    'user_privacy.item5.list4.5': 'Giới thiệu về cài đặt cookie của khách hàng',
    'user_privacy.item5.list4.6':
      'Bạn có thể chọn cài đặt để gửi và nhận cookie từ "Cho phép tất cả cookie", "Từ chối tất cả cookie", "Thông báo cho người dùng khi nhận được cookie", v.v. Phương pháp cài đặt khác nhau tùy thuộc vào trình duyệt. Vui lòng kiểm tra trình đơn "Trợ giúp" của trình duyệt của bạn để biết cách đặt cookie.',
    'user_privacy.item5.list4.7':
      'Bạn có thể chọn không tham gia cài đặt cookie của Google bằng cách truy cập trang sau.',
    'user_privacy.item5.list4.8':
      'Xin lưu ý rằng nếu bạn chọn cài đặt để từ chối tất cả cookie, bạn có thể bị hạn chế trong việc sử dụng các dịch vụ khác nhau trên Internet, chẳng hạn như không thể nhận các dịch vụ yêu cầu xác thực.',
    'user_privacy.item5.list5.title': 'Giới thiệu về việc sử dụng đối tượng tùy chỉnh',
    'user_privacy.item5.list5.description':
      'Yêu cầu Facebook và Twitter (sau đây gọi là "Facebook, v.v.") hiển thị các quảng cáo tùy chỉnh cho khách hàng dựa trên trạng thái sử dụng của trang web của chúng tôi và sử dụng các quảng cáo được hiển thị đó với mục đích tìm hiểu tình hình, chúng tôi sử dụng đối tượng tùy chỉnh hàm số.',

    'user_privacy.item5.list6.title': 'Chức năng đối tượng tùy chỉnh là gì?',
    'user_privacy.item5.list6.description':
      'Đây là chức năng đối chiếu thông tin của khách hàng đã đăng ký với chúng tôi với thông tin đã đăng ký với Facebook, v.v. và gửi quảng cáo của chúng tôi trên trang của khách hàng trên Facebook, v.v. cho các khách hàng phù hợp. Với chức năng này, Facebook, v.v. sẽ không lấy thông tin cá nhân của khách hàng của chúng tôi.',

    'user_privacy.item5.list7.title': 'Chọn không tham gia các tính năng đối tượng tùy chỉnh trên Twitter',
    'user_privacy.item5.list7.description':
      'Nếu bạn muốn chọn không tham gia tính năng đối tượng tùy chỉnh trên Twitter, vui lòng làm theo quy trình từ URL này.',

    'user_privacy.item6.title': 'Về việc rút lại sự đồng ý',
    'user_privacy.item6.description1': 'Nếu có bất kỳ chỉnh sửa nào đối với thông tin đã đăng ký mà khách hàng đã đăng ký,',
    'user_privacy.item6.description2':
      'Nếu bạn muốn tạm ngừng sử dụng thông tin cá nhân của mình, vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi. Tuy nhiên, xin lưu ý rằng sau khi hoàn tất thủ tục rút tiền, bạn sẽ không thể sử dụng một số hoặc tất cả các dịch vụ do trang web của chúng tôi cung cấp.',
    'user_privacy.item6.description3': 'Liên kết dịch vụ khách hàng',

    'user_privacy.item7.title': 'Các thay đổi trong chính sách bảo mật, v.v.',
    'user_privacy.item7.description':
      'Do các trường hợp khác nhau, chúng tôi có thể thay đổi, chỉnh sửa, thêm hoặc xóa một phần chính sách bảo mật ở trên, nhưng trong trường hợp đó, chúng tôi sẽ thông báo cho bạn trên trang web. Trừ khi có chỉ định khác, những thay đổi này sẽ có hiệu lực ngay khi chúng được đăng trên trang web này.',

    'user_privacy.item8.title': 'Các công ty liên kết của chúng tôi',
    'user_privacy.item8.description1': 'Các công ty trực thuộc là các công ty sau đây.',
    'user_privacy.item8.description2': 'Công ty TNHH WILLER',
    'user_privacy.item8.description3': 'Công ty TNHH WILLER EXPRESS',
    'user_privacy.item8.description4': 'Công ty TNHH WILLER TRAINS',
    'user_privacy.item8.description5': 'Công ty TNHH Dịch vụ Bảo hiểm Phi nhân thọ WILLER',
    'user_privacy.item8.description6': 'Tetsu Fund Co., Ltd.',
    'user_privacy.item8.description7': 'Công ty TNHH Hệ thống Vận hành Khách sạn (Khách sạn Hakuba)',
    'user_privacy.item8.description8': 'Công ty TNHH WILLER VIỆT NAM',
    'user_privacy.item8.description9': 'Công ty TNHH WILLER VTI',
    'user_privacy.item8.description10': 'Các công ty khác có quan hệ vốn với Công ty TNHH WILLER.',
    'user_privacy.item8.description11': 'Các câu hỏi khác liên quan đến chính sách bảo mật',

    // mobi term of service
    'mobi_term.title': 'Điều khoản Dịch vụ của mobi',
    'mobi_term.description':
      'Công ty TNHH WILLER (sau đây gọi là "công ty của chúng tôi") chủ yếu đề cập đến "Ứng dụng di động cộng đồng mobi" (sau đây gọi là "ứng dụng này") cho các điều khoản sử dụng dịch vụ mobi (sau đây gọi là "các điều khoản "). Nó xác định các điều khoản sử dụng của dịch vụ mobi được vận hành và cung cấp thông qua.',

    'mobi_term.item1.title': 'Điều 1 (Quy tắc chung)',
    'mobi_term.item1.content1.title':
      'Các Điều khoản này là các điều khoản riêng lẻ trong Điều khoản sử dụng ứng dụng cộng đồng mobi (sau đây được gọi là "Điều khoản gốc"), và trừ khi có quy định khác liên quan đến dịch vụ mobi, các Điều khoản này sẽ được ưu tiên.',
    'mobi_term.item1.content2.title':
      'Khi đăng ký dịch vụ mobi, người dùng sẽ được coi là đã đồng ý với thỏa thuận này, hướng dẫn thủ tục đính kèm, quy tắc sử dụng, v.v. (sau đây gọi là "thỏa thuận này, v.v.").',
    'mobi_term.item1.content3.title':
      'Theo các điều khoản ban đầu, chúng tôi sẽ là nhà cung cấp và dịch vụ mobi sẽ là một dịch vụ riêng lẻ.',
    'mobi_term.item1.content4.title':
      'Nội dung của thỏa thuận này, vv có thể được thay đổi theo quyết định của chúng tôi mà không cần thông báo trước cho người dùng trong các trường hợp sau. Trong trường hợp này, những nội dung thay đổi của thỏa thuận này sẽ được áp dụng cho việc sử dụng sau ngày thay đổi. Ngoài ra, khi chúng tôi thay đổi nội dung của thỏa thuận này, v.v., chúng tôi sẽ thông báo trước cho người dùng về những nội dung đã thay đổi trong một khoảng thời gian hợp lý.',
    'mobi_term.item1.content4.list1':
      'Khi nội dung của thỏa thuận này, v.v. được thay đổi vì lợi ích chung của người dùng',
    'mobi_term.item1.content4.list2':
      'Các thay đổi đối với nội dung của các Điều khoản này, v.v. không vi phạm mục đích của các Điều khoản này, v.v. và là hợp lý dựa trên sự cần thiết của các thay đổi, tính phù hợp của nội dung đã thay đổi, nội dung thay đổi và các trường hợp khác liên quan đến những thay đổi. Khi nào.',
    'mobi_term.item1.content5.title':
      'Ngay cả khi một số quy định của các Điều khoản này bị pháp luật hoặc phán quyết vô hiệu hoặc thu hồi thì hiệu lực, tính hợp pháp và hiệu lực pháp lý của các điều khoản còn lại vẫn sẽ có hiệu lực ...',

    'mobi_term.item2.title': 'Điều 2 (Nội dung của dịch vụ mobi)',
    'mobi_term.item2.content1.title':
      'Trong dịch vụ mobi, người dùng có thể nhận được các dịch vụ được chỉ định trong từng mục sau đây.',
    'mobi_term.item2.content1.list1':
      'Từ các địa điểm lên / xuống ảo do Công ty thiết lập, các hành khách thông thường của hãng vận chuyển xe khách (sau đây gọi là "người vận chuyển") liên kết với Công ty trong phần nơi lên và xuống do ứng dụng này thiết lập. Dịch vụ vận tải bằng ô tô (sau đây gọi là "dịch vụ vận chuyển")',
    'mobi_term.item2.content1.list2':
      'Dịch vụ vận chuyển của hãng vận chuyển liên kết với công ty của chúng tôi trong phần địa điểm lên máy bay và địa điểm xuống xe được đặt thông qua trung tâm cuộc gọi của chúng tôi từ địa điểm lên máy bay / xuống xe ảo do công ty chúng tôi thiết lập.',
    'mobi_term.item2.content1.list3': 'Dịch vụ cố định trong 30 ngày cho 2 dịch vụ trước đó',
    'mobi_term.item2.content1.list4':
      'Dịch vụ tùy chọn bao gồm những người thân sống với người dùng trong dịch vụ của số trước',
    'mobi_term.item2.content2.title':
      'Ở đoạn trên, hợp đồng vận tải hành khách chung bằng xe ô tô sẽ được ký kết giữa người sử dụng và công ty vận tải, hợp đồng vận tải phải tuân theo các điều kiện vận tải do công ty vận tải quy định riêng.',

    'mobi_term.item3.title': 'Điều 3 (Phí sử dụng)',
    'mobi_term.item3.content1.title':
      'Bất cứ khi nào người dùng đăng ký dịch vụ của Đoạn 1, Khoản 1 hoặc 2 của Điều trước đó (sau đây gọi là "sử dụng một lần"), số tiền được chỉ định trong bảng phí sử dụng được chỉ định riêng là số tiền được chỉ định trong mỗi các mục sau đây. Thanh toán sẽ được thực hiện theo phương thức quy định trong.',
    'mobi_term.item3.content1.list1':
      'Khi nộp hồ sơ qua ứng dụng này (số 1): Thanh toán bằng thẻ tín dụng của đại lý thanh toán được quy định trong hợp đồng ban đầu hoặc thanh toán trực tiếp bằng tiền mặt với nhà vận chuyển khi cung cấp dịch vụ vận chuyển',
    'mobi_term.item3.content1.list2':
      'Khi nộp hồ sơ qua tổng đài của chúng tôi (số 2): Thanh toán trực tiếp với bên vận chuyển khi cung cấp dịch vụ vận chuyển',
    'mobi_term.item3.content2.title':
      'Khi người dùng đăng ký dịch vụ được quy định tại Đoạn 1, Khoản 3 của Điều trước (sau đây được gọi là "mức sử dụng cố định"), mức phí cố định được chỉ định trong bảng phí sử dụng có thể được đặt thành bất kỳ phương thức nào được chỉ định trong mỗi mục sau đây. Sẽ được thanh toán bởi.',
    'mobi_term.item3.content2.list1': 'Thanh toán bằng thẻ tín dụng của đại lý thanh toán',
    'mobi_term.item3.content2.list2':
      'Chuyển khoản vào tài khoản ngân hàng do công ty chúng tôi chỉ định (phí chuyển khoản do người dùng thanh toán)',
    'mobi_term.item3.content2.list3': 'Sự chuyển khoản',
    'mobi_term.item3.content3.title':
      ' Ngày đến hạn thanh toán trong mỗi mục của khoản trước sẽ được nêu trong mỗi mục sau đây.',
    'mobi_term.item3.content3.list1':
      'Trong trường hợp đại lý thanh toán, tại thời điểm đăng ký dịch vụ cố định 30 ngày',
    'mobi_term.item3.content3.list2': 'Trong trường hợp chuyển khoản: Ngày do chúng tôi chỉ định trong ứng dụng này',
    'mobi_term.item3.content3.list3':
      'Đối với trường hợp chuyển tiền: Ngày do tổ chức tài chính thực hiện chuyển tiền quy định',
    'mobi_term.item3.content4.title':
      'Khi người dùng đăng ký dịch vụ tùy chọn được nêu trong Đoạn 1, Khoản 4 của Điều trước đó (sau đây gọi là "sử dụng cho gia đình"), việc sử dụng theo tỷ lệ cố định được áp dụng cho chính người dùng đó tùy thuộc vào số lượng người áp dụng Tương tự, số tiền quy định trong bảng phí sử dụng sẽ được cộng và thanh toán. Trong trường hợp này, phương thức thanh toán sẽ giống như phương thức thanh toán phí sử dụng của chính người dùng.',
    'mobi_term.item3.content5.title':
      'Phí sử dụng quy định tại Khoản 2 và 3 Điều này không được hoàn trả vì bất kỳ lý do gì như người dùng đã sử dụng hay chưa, số lần sử dụng hoặc bất kỳ lý do nào khác.',

    'mobi_term.item4.title': 'Điều 4 (Cập nhật)',
    'mobi_term.item4.content1.title':
      'Đối với việc sử dụng tỷ giá cố định (bao gồm cả việc sử dụng cho gia đình đi kèm với nó), nếu phương thức thanh toán là thanh toán bằng thẻ tín dụng hoặc chuyển khoản, nếu không có yêu cầu từ người dùng vào ngày cuối cùng sử dụng tỷ giá cố định, thì phương thức thanh toán sẽ tự động bắt đầu từ ngày hôm sau. Người ta giả định rằng việc sử dụng tỷ lệ cố định mới sẽ bắt đầu trong các điều kiện tương tự. Trong trường hợp này, thanh toán bằng thẻ tín dụng sẽ tự động được khấu trừ vào ngày bắt đầu sử dụng theo tỷ lệ cố định mới và ghi nợ trực tiếp sẽ tự động được khấu trừ vào ngày do tổ chức tài chính ghi nợ trực tiếp chỉ định.',
    'mobi_term.item4.content2.title':
      'Đối với việc sử dụng tỷ giá cố định (bao gồm cả sử dụng cho gia đình đi cùng) có phương thức thanh toán là chuyển khoản, nếu phí sử dụng được thanh toán vào ngày cuối cùng sử dụng tỷ giá cố định, các điều kiện tương tự sẽ được áp dụng kể từ ngày sau ngày đó. việc sử dụng sẽ bắt đầu.',
    'mobi_term.item4.content3.title':
      'Nếu không thể xác nhận rằng phí sử dụng đã được thanh toán vào ngày làm việc sau ngày thanh toán của mức sử dụng cố định mới được nêu trong mục 2 ở trên, Công ty sẽ sử dụng mức phí sử dụng cố định ngay cả sau ngày bắt đầu của việc sử dụng mới này. sử dụng tỷ lệ cố định. Có thể được hủy bỏ ngay lập tức.',

    'mobi_term.item5.title': 'Điều 5 (Hủy dịch vụ vận chuyển)',
    'mobi_term.item5.content1.title':
      'Người thân của người dùng và người dùng mà gia đình sử dụng (sau đây gọi là "người dùng, v.v.") sau khi đăng ký dịch vụ vận chuyển của dịch vụ mobi cho đến khi xe của công ty vận tải đến điểm khởi hành. Trong thời gian chờ đợi, bạn có thể hủy đơn đăng ký dịch vụ vận chuyển này bất kỳ lúc nào.',
    'mobi_term.item5.content2.title':
      'Nếu chúng tôi xác nhận việc hủy bỏ Đoạn 1 của Điều này bởi người dùng một lần, chúng tôi sẽ nhanh chóng hoàn lại phí sử dụng đã thanh toán.',

    'mobi_term.item6.title': 'Điều 6 (Nghĩa vụ của Người dùng)',
    'mobi_term.item6.content1.title':
      'Khi nhận dịch vụ vận tải, người sử dụng, v.v ... phải tuân thủ Luật Giao thông đường bộ, hợp đồng vận chuyển do công ty vận tải quy định và hướng dẫn của nhân viên vận tải. Ngoài ra, nếu thuyền viên của phương tiện cung cấp dịch vụ vận hành thừa nhận một cách hợp lý rằng người sử dụng, v.v. đã vi phạm hoặc có thể vi phạm Điều này, thuyền viên, với tư cách là người quản lý phương tiện, sẽ thông báo cho người dùng này, v.v. mặt khác, bạn có thể ngay lập tức từ chối lên tàu hoặc yêu cầu xuống xe.',
    'mobi_term.item6.content2.title':
      'Người dùng, v.v. không được lặp lại đơn đăng ký và hủy bỏ một cách không cần thiết.',
    'mobi_term.item6.content3.title':
      'Dịch vụ mobi (tuy nhiên, giới hạn cho mục đích sử dụng được xếp hạng và sử dụng cho gia đình) chỉ dành cho người dùng, v.v. và người đi cùng, chẳng hạn như người dùng, là một người dùng riêng biệt, v.v. Trừ khi bạn đăng ký, bạn không thể đi cùng chúng ta.',

    'mobi_term.item7.title': 'Điều 7 (Ứng phó với tai nạn trong quá trình vận chuyển)',
    'mobi_term.item7.content1.title':
      'Trong trường hợp không chắc người dùng, v.v. bị thiệt hại do tai nạn hoặc sự cố (sau đây gọi là "tai nạn, v.v.") xảy ra khi sử dụng dịch vụ vận tải (bao gồm cả thời gian lên xuống xe), người dùng v.v ... Căn cứ vào các điều kiện vận chuyển do người chuyên chở thiết lập, vấn đề sẽ được thảo luận và giải quyết với người vận chuyển.',
    'mobi_term.item7.content2.title':
      'Công ty có thể hợp tác giải quyết các tai nạn, v.v. theo quyết định riêng của mình, nhưng không chịu trách nhiệm về bất kỳ thiệt hại nào gây ra cho người dùng do tai nạn, v.v.',
    'mobi_term.item7.content3.title':
      'Khi người dùng, v.v. hoặc người vận chuyển, v.v. nộp một thủ tục để giải quyết tranh chấp trong Điều này, các quy định của Điều 11 của Giao ước gốc sẽ không được áp dụng, nhưng sẽ được giải thích dựa trên luật pháp Nhật Bản và được giải quyết tại tòa án ở Nhật Bản. Tăng lên.',

    'mobi_term.item8.title': 'Điều 8 (Thông tin về người dùng, v.v.)',
    'mobi_term.item8.content1.title':
      'Bằng cách đăng ký dịch vụ mobi, người dùng, v.v. sẽ nhận được thông tin cá nhân như tên, giới tính, thông tin liên lạc của người dùng, v.v. trong phạm vi cần thiết cho mục đích cung cấp dịch vụ vận chuyển từ WILLERS thông qua ứng dụng này của Công ty và Công ty vận tải. Giả định rằng bạn đã nhận được điều khoản và đã đồng ý với chính sách bảo mật hoặc chính sách xử lý thông tin cá nhân do Công ty và nhà vận chuyển đặt ra.',
    'mobi_term.item8.content2.title':
      'Công ty và công ty vận tải sẽ quản lý một cách thích hợp thông tin cá nhân của người dùng, v.v. có được dựa trên đoạn trên hoặc trong quá trình cung cấp dịch vụ mobi, dựa trên Luật bảo vệ thông tin cá nhân và các quy định liên quan.',
    'mobi_term.item8.content3.title':
      'Về phần trên, chính sách bảo mật hoặc chính sách xử lý thông tin cá nhân, v.v. do Công ty và nhà cung cấp thiết lập, và thông tin liên hệ về thông tin cá nhân sẽ được đăng trên ứng dụng này.',

    'mobi_term.item9.title': 'Điều 9 (Loại trừ các lực lượng chống đối xã hội)',
    'mobi_term.item9.content1.title':
      'Người dùng, v.v. phải cam kết bằng cách nêu rõ rằng họ không thuộc bất kỳ mục nào sau đây và họ không có mối quan hệ trao đổi chặt chẽ với các cá nhân hoặc nhóm trong từng mục sau đây.',
    'mobi_term.item9.content1.list1': 'Boryokudan',
    'mobi_term.item9.content1.list2':
      'Thành viên của băng đảng xã hội đen (bao gồm cả thành viên liên kết; sau đây sẽ áp dụng như vậy)',
    'mobi_term.item9.content1.list3':
      'Một tổ chức trong đó một công ty liên quan đến xã hội đen hoặc xã hội đen hoặc một thành viên của xã hội đen giữ một vị trí quan trọng về mặt đầu tư hoặc thực hiện kinh doanh.',
    'mobi_term.item9.content1.list4':
      'Sokaiya, người ủng hộ phong trào xã hội Goro và các lực lượng chống đối xã hội khác',
    'mobi_term.item9.content1.list5':
      'Các nhóm bạo lực tình báo đặc biệt và các nhóm khác thuộc lực lượng chống đối xã hội hoặc thành viên của chúng',
    'mobi_term.item9.content1.list6': 'Các mục khác tương đương với các mục trước đó',
    'mobi_term.item9.content2.title':
      'Người dùng, v.v. sẽ cam kết bằng cách bày tỏ rằng họ sẽ không tự mình thực hiện bất kỳ hành vi nào sau đây hoặc bằng cách sử dụng bên thứ ba.',
    'mobi_term.item9.content2.list1': 'Yêu cầu bạo lực hoặc vô cớ vượt quá trách nhiệm pháp lý',
    'mobi_term.item9.content2.list2': 'Hành vi đe dọa hoặc sử dụng bạo lực liên quan đến giao dịch',
    'mobi_term.item9.content2.list3':
      'Phổ biến tin đồn, gây tổn hại đến uy tín của bên kia hoặc can thiệp vào hoạt động kinh doanh của bên kia bằng cách sử dụng quyền lực hoặc giả mạo',
    'mobi_term.item9.content2.list4': 'Các hành vi khác tương đương với các mục trước',
    'mobi_term.item9.content3.title':
      'Công ty và đơn vị vận tải sẽ ngay lập tức ngừng cung cấp dịch vụ mobi và từ chối cung cấp nếu người sử dụng, v.v ... vi phạm hoặc xác định rằng vi phạm các mục nêu ở hai khoản trên, các trường hợp này, các bên, v.v ... có thể phải chịu tất cả các thiệt hại do Công ty và công ty vận tải gây ra do hành vi vi phạm của chính họ (bao gồm nhưng không giới hạn lợi nhuận bị mất do tổn hại danh tiếng, phí luật sư, v.v.) sẽ được bồi hoàn.',

    'mobi_term.item10.title': 'Điều 10 (Những sửa đổi thỏa đáng của thỏa thuận ban đầu)',
    'mobi_term.item10.description':
      'Các quy định từ Điều 4 đến Điều 10 của thỏa thuận ban đầu sẽ được áp dụng với những sửa đổi phù hợp cho thỏa thuận này. "Công ty" trong thỏa thuận ban đầu được áp dụng với những sửa đổi thích hợp sẽ là Công ty TNHH WILLER được chỉ định trong thỏa thuận này và "người dùng" sẽ được đọc là người dùng, v.v. được chỉ định trong thỏa thuận này.',

    // Subcription landing page
    'sub.title1': 'Trước 30 ngày từ hôm qua',
    'sub.title2': 'Trước 31 đến 60 ngày',
    'sub.title3': 'Trước 61 đến 90 ngày',

    'sub.description1': 'Đi lại tiện lợi, giá tốt mọi lúc, mọi nơi',
    'sub.description2': 'Gói hội viên',

    'sub.mobi_define': 'mobi là loại hình di chuyển mới dành cho mọi người.',
    'sub.mobi_define1': '',
    'sub.mobi_define2': 'Tham gia gói hội viên ngay để có thể di chuyển mọi lúc mọi nơi tùy thích.',
    'sub.mobi_define3': '',

    'sub.feature': 'Đặc điểm',
    'sub.feature_description': 'Đặc điểm của gói hội viên',
    'sub.feature_description1': 'Giá tiền cố định {{amount}}',
    'sub.feature_description1.2': '',
    'sub.feature_description2': 'Trọn gói ngày',
    'sub.feature_description3': 'Đón trả tại điểm bạn muốn đi và đến',
    'sub.feature_description4': 'trong khu vực cung cấp dịch vụ.',
    'sub.feature_description5': 'Tình trạng sử dụng của bạn',

    'sub.scope1_description1': 'Di chuyển tiện lợi',
    'sub.scope1_description2': '',
    'sub.scope1_description3': 'Chúng tôi sẽ đón bạn tại điểm đón',
    'sub.scope1_description4': 'và đưa bạn đến nơi mong muốn.',
    'sub.scope1_description5': 'Chọn khu vực bạn muốn sử dụng',

    'sub.scope2_description1': 'Phương tiện lưu thông trong thành phố',
    'sub.scope2_description2': '',
    'sub.scope2_description3': 'mobi chạy liên tục quanh khu vực bạn sinh sống.',
    'sub.scope2_description4': 'Đặt chuyến qua APP này, xe sẽ đón bạn ngay!',

    'sub.scope3_description1': 'Thêm thành viên gia đình vào gói dịch vụ di chuyển không giới hạn chỉ với {{fee}}/người',
    'sub.scope3_description2': '',
    'sub.scope3_description3': 'Tối đa {{member}} thành viên gia đình',
    'sub.scope3_description4': 'có thể sử dụng gói dịch vụ không giới hạn tùy thích.',
    'sub.scope3_description5': 'Xem thêm chi tiết gói Gia đình',
    'established_year': 'Thành lập ngày 17 tháng 6 năm 2021 Thực hiện ngày 17 tháng 6 năm 2021',

    // Family option
    'family_option.title1': 'Di chuyển cùng cả gia đình, vừa rẻ vừa tiện',
    'family_option.description': 'Gói hội viên',
    'family_option.title2': 'Gói gia đình',
    'family_option.introduce': 'Gia đình bạn có thể sử dụng gói dịch vụ của mobi với {{value}} mỗi người',
    'family_option.introduce1': '',
    'family_option.introduce2': '',
    'family_option.feature': 'Đặc điểm',
    'family_option.feature_description': 'Đặc điểm của Gói Gia đình',
    'family_option.feature1_title': 'Có thể thêm thành viên gia đình vào gói dịch vụ chỉ với {{value}} mỗi người',
    'family_option.feature1_title2': '',
    'family_option.feature1_description1': '',
    'family_option.feature1_description2': 'Gia đình bạn có thể tự do sử dụng gói dịch vụ',
    'family_option.feature1_description3': 'tối đa đến {{value}} thành viên',
    'family_option.feature2_title1': 'Dịch vụ có thể sử dụng trong gói Gia đình',
    'family_option.feature2_title2': 'không thay đổi.',
    'family_option.feature2_description1': 'Thành viên gia đình chỉ cần',
    'family_option.feature2_description2': 'tải ứng dụng và sử dụng',
    'family_option.feature2_description3': 'Dễ dàng đăng kí cho gia đình',
    'family_option.feature2_description4': 'Chỉ cần đăng ký là có thể sử dụng dịch vụ mobi không giới hạn',
    'family_option.feature2_description5': '',
    'family_option.feature3_title1': 'Ngay cả thành viên gia đình không dùng điện thoại thông minh vẫn có thể đặt chuyến mobi qua điện thoại.',
    'family_option.feature3_title2': '',
    'family_option.feature3_description1': 'Nhân viên điều hành sẽ điều phối đặt chỗ',
    'family_option.feature3_description2': 'và hướng dẫn bạn đến nơi lên xe một cách chu đáo.',
    'family_option.feature4_title1': 'Di chuyển không giới hạn đến bất kì đâu trong phạm vi khu vực cung cấp dịch vụ.',
    'family_option.feature4_title2': '',
    'family_option.feature4_description1': 'mobi chạy liên tục quanh khu vực bạn sinh sống.',
    'family_option.feature4_description2': 'Đặt chuyến qua APP này, xe sẽ đón bạn ngay!',
    'family_option.fast_feature': 'Chọn khu vực bạn muốn sử dụng',
    'family_option.feature5_title1': 'Xe sẽ chạy đến địa điểm bạn đã chọn',
    'family_option.feature5_title2': 'trong vòng 10-15 phút',
    'family_option.feature5_description1': 'mobi chạy liên tục quanh khu vực bạn sinh sống.',
    'family_option.feature5_description2': 'Đặt chuyến qua APP này, xe sẽ đón bạn ngay!',
    'family_option.japan_only': 'Chỉ Nhật Bản',

    // Ticket Landing Page
    'ticket.title1': 'Đi xe rẻ hơn thanh toán một lần!',
    'ticket.title2': 'Gói vé lượt',
    'ticket.background_title1': 'Vé có giá phải chăng',
    'ticket.background_title2': 'Vé lượt',
    'ticket.content1': 'mobi là',
    'ticket.content2': 'một cảm giác mới về phương tiện giao thông chia sẻ.',
    'ticket.content3': 'Gói vé lượt tiết kiệm và hiệu quả cho sử dụng mobi.',
    'ticket.ticket_price': 'Gói vé lượt',
    'ticket.ticket_price.content': 'Tại sao đây lại là món hời?',
    'ticket.save_money.content1': 'Tiết kiệm nhờ tự động gia hạn',
    'ticket.save_money.content2': 'Gói vé lượt này được mua tự động',
    'ticket.save_money.content3': 'cho mỗi giai đoạn áp dụng gói.',
    'ticket.feature1.content1': 'Lợi hơn thanh toán một lần',
    'ticket.feature1.content2': 'Vì bạn mua nhiều vé cùng một lúc',
    'ticket.feature1.content3': 'nên bạn có thể tiết kiệm chi phí cho mỗi chuyến đi.',
    'ticket.feature2.content1': 'Sử dụng cho người đi cùng',
    'ticket.feature2.content2': 'Có thể được sử dụng cho người đi cùng',
    'ticket.feature2.content3': 'như thành viên gia đình, bạn bè...',
    'ticket.feature3.content1': 'Số lượt chưa sử dụng sẽ được chuyển sang lần sau',
    'ticket.feature3.content2': 'Số lượt chưa sử dụng lần trước sẽ được chuyển sang',
    'ticket.feature3.content3': 'lần sau nên bạn có thể sử dụng mà không lãng phí.',
    'ticket.footer1': '※ Lượt trước đó sẽ không được chuyển.',
    'ticket.footer2': '※ Nếu bạn ngừng gia hạn tự động, những lượt chưa sử dụng sẽ không được chuyển sang.',

    // terms_mobi
    'terms.title': 'mobi Community Mobility<br>Điều khoản dịch vụ của ứng dụng',
    'terms.description':
      'Điều khoản sử dụng Mobi Community Mobility App (sau đây gọi là "Điều khoản") là một ứng dụng "Cộng đồng mobi" được điều hành và cung cấp bởi WILLERS PTE. LTD. (Sau đây gọi là "Công ty"), một tập đoàn của Singapore. Nó định nghĩa điều khoản sử dụng của "Ứng dụng di động" (sau đây được gọi là "Ứng dụng").',

    'terms.item1.title': 'Điều 1 (Quy tắc chung)',
    'terms.item1.content1.title':
      'Ứng dụng này là một người đã cài đặt ứng dụng này trên một thiết bị đầu cuối thông tin di động như điện thoại thông minh (sau đây gọi là thiết bị đầu cuối tương thích) do chính họ sở hữu hoặc quản lý sau khi đồng ý với thỏa thuận này (sau đây gọi là "người dùng"). được phép sử dụng ứng dụng này.).',
    'terms.item1.content2.title':
      'Bằng cách sử dụng ứng dụng này hoặc xem hoặc đăng ký dịch vụ riêng lẻ được quy định tại Điều 2, người dùng sẽ được gọi là "thỏa thuận cá nhân" được quy định trong Điều 2 ngoài thỏa thuận này.) Và các điều khoản và điều kiện kèm theo thỏa thuận này hoặc các thỏa thuận riêng lẻ (sau đây gọi chung là "các thỏa thuận này, v.v.") được coi là đã được xác nhận và đồng ý.',
    'terms.item1.content3.title':
      'Nội dung của thỏa thuận này, v.v. có thể được thay đổi mà không cần thông báo trước cho người dùng trong các trường hợp sau theo quyết định của Công ty hoặc người quản lý của thỏa thuận cá nhân. Trong trường hợp này, những nội dung thay đổi của thỏa thuận này sẽ được áp dụng cho việc sử dụng sau ngày thay đổi. Ngoài ra, khi chúng tôi thay đổi nội dung của thỏa thuận này, v.v., chúng tôi sẽ thông báo trước cho người dùng về những nội dung đã thay đổi trong một khoảng thời gian hợp lý.',
    'terms.item1.content3.list1': 'Khi nội dung của thỏa thuận này, v.v. được thay đổi vì lợi ích chung của người dùng',
    'terms.item1.content3.list2':
      'Các thay đổi đối với nội dung của các Điều khoản này, v.v. không vi phạm mục đích của các Điều khoản này, v.v. và là hợp lý dựa trên sự cần thiết của các thay đổi, tính phù hợp của nội dung đã thay đổi, nội dung thay đổi và các trường hợp khác liên quan đến những thay đổi. Khi nào.',
    'terms.item1.content4.title':
      'Ngay cả khi một số quy định của các Điều khoản này bị pháp luật hoặc phán quyết vô hiệu hoặc thu hồi thì hiệu lực, tính hợp pháp và hiệu lực pháp lý của các điều khoản còn lại vẫn sẽ có hiệu lực ...',

    'terms.item2.title': 'Điều 2 (Các dịch vụ được cung cấp)',
    'terms.item2.content1.title':
      'Trong ứng dụng này, người dùng có thể nhận được dịch vụ được chỉ định trong từng mục sau đây (sau đây gọi là "dịch vụ này") miễn phí.',
    'terms.item2.content1.list1':
      'Tìm kiếm tuyến đường: Bằng cách sử dụng phương pháp tìm kiếm do ứng dụng này cung cấp, bạn có thể tìm kiếm và hiển thị một tuyến đường hoặc nhiều tuyến đường cho tuyến đường và phương thức vận chuyển tối ưu từ điểm khởi hành đến điểm đến trong một khu vực cụ thể.',
    'terms.item2.content1.list2':
      'Tìm kiếm dịch vụ cá nhân: Một hoặc nhiều dịch vụ vận tải được cung cấp bởi các doanh nghiệp không phải công ty chúng tôi (sau đây gọi là "dịch vụ cá nhân") liên quan đến việc tìm kiếm tuyến đường trong số trước hoặc do kết quả tìm kiếm của người dùng. Đó là một cái gì đó có thể được hiển thị.',
    'terms.item2.content1.list3':
      'Đại lý thanh toán: Một công ty cung cấp các dịch vụ cá nhân thông qua đại lý thanh toán của bên thứ ba khi thanh toán các chi phí phát sinh thông qua việc sử dụng ứng dụng này (sau đây gọi là "công ty cung cấp"). Tuy nhiên, chúng tôi sẽ thay mặt bạn thanh toán khoản phí này. Phương thức thanh toán cho các dịch vụ riêng lẻ sẽ được xác định theo các điều khoản và điều kiện riêng.',
    'terms.item2.content2.title':
      'Khi người dùng đăng ký một dịch vụ riêng lẻ, Công ty sẽ giới thiệu từng dịch vụ riêng lẻ thông qua ứng dụng này hoặc trong một số trường hợp, hoạt động như một đại lý thanh toán và người dùng sẽ ký hợp đồng cho từng dịch vụ chỉ với nhà cung cấp.',
    'terms.item2.content3.title':
      'Tùy thuộc vào vị trí của người dùng, thiết bị đầu cuối thông tin di động được sử dụng, trạng thái giao tiếp và các môi trường sử dụng khác, một số hoặc tất cả các chức năng của dịch vụ này có thể không khả dụng.',
    'terms.item2.content4.title':
      'Công ty có thể thay đổi, thêm hoặc bãi bỏ toàn bộ hoặc một phần của dịch vụ này hoặc dịch vụ riêng lẻ theo quyết định riêng của mình mà không cần có sự đồng ý trước của người dùng (sau đây gọi là "thay đổi, v.v."). Công ty không chịu trách nhiệm về bất kỳ thiệt hại hoặc bất lợi nào gây ra cho người dùng do những thay đổi trong dịch vụ này hoặc các dịch vụ riêng lẻ.',
    'terms.item2.content5.title':
      'Nếu có nội dung mâu thuẫn giữa từng thỏa thuận riêng lẻ và thỏa thuận này thì các quy định của từng thỏa thuận riêng lẻ, v.v. sẽ được ưu tiên áp dụng trừ khi có quy định khác.',

    'terms.item3.title': 'Điều 3 (Đăng ký thẻ tín dụng)',
    'terms.item3.content1.title':
      'Khi đăng ký thẻ tín dụng cho đại lý thanh toán cho các dịch vụ cá nhân, người dùng chỉ có thể đăng ký thẻ tín dụng dưới danh nghĩa của người dùng.',
    'terms.item3.content2.title':
      'Người dùng không được sử dụng thẻ tín dụng của người khác, nhập sai thông tin thẻ tín dụng hoặc thực hiện bất kỳ hành vi nào khác mà Công ty cho là không phù hợp. Công ty có thể yêu cầu người dùng bồi thường cho những thiệt hại, v.v. do người dùng vi phạm những vi phạm này gây ra.',
    'terms.item3.content3.title':
      'Công ty có thể thay đổi thẻ tín dụng có thể được sử dụng cho dịch vụ này và các dịch vụ cá nhân theo quyết định của Công ty hoặc cơ quan thanh toán liên kết.',
    'terms.item3.content4.title':
      'Nếu công ty phát hành thẻ hoặc đại lý thanh toán từ chối sử dụng thẻ tín dụng đã được sử dụng để thanh toán của người dùng vì một lý do nào đó, hoặc nếu xác định được rằng thẻ tín dụng được sử dụng không phù hợp theo các tiêu chuẩn do Công ty quy định, Công ty sẽ, có thể thay đổi phương thức thanh toán và các biện pháp khác mà Công ty cho là cần thiết mà không cần sự đồng ý của người sử dụng.',
    'terms.item3.content5.title':
      'Nếu thẻ tín dụng được sử dụng làm phương thức thanh toán cho dịch vụ cá nhân và phí thay đổi hoặc phí hủy bỏ phát sinh do thay đổi hoặc hủy bỏ nội dung hợp đồng vì sự thuận tiện của người dùng đối với dịch vụ cá nhân được quy định trong thỏa thuận cá nhân, thay đổi như vậy Phí và phí hủy bỏ cũng sẽ được thanh toán thông qua thẻ tín dụng mà Công ty sử dụng.',

    'terms.item4.title': 'Điều 4 (Cách thông báo cho người dùng)',
    'terms.item4.description':
      'Nếu Công ty và nhà cung cấp cần thông báo về việc sử dụng dịch vụ này và các dịch vụ riêng lẻ, dựa trên nhận định của riêng họ, thông báo cho ứng dụng này, thông báo bằng e-mail đến địa chỉ e-mail đã đăng ký cho ứng dụng này, hoặc self Có thể đăng trên trang chủ do công ty quản lý hoặc bằng một hoặc nhiều phương pháp thích hợp khác.',

    'terms.item5.title': 'Điều 5 (Thông tin cá nhân)',
    'terms.item5.description':
      'Công ty sẽ xử lý thông tin cá nhân của người dùng có được thông qua việc cung cấp ứng dụng này hoặc dịch vụ này theo các quy định trong chính sách bảo mật của chúng tôi và luật và quy định về thông tin cá nhân hiện hành, và người dùng đồng ý với điều này.',

    'terms.item6.title': 'Điều 6 (Xử lý dữ liệu)',
    'terms.item6.content1.title':
      'Ngoài các quy định của bài viết trước, Công ty sẽ không truy cập vào dữ liệu do người dùng đăng ký hoặc tiết lộ cho bên thứ ba. Tuy nhiên, điều này không áp dụng cho các trường hợp được nêu trong các mục sau và người dùng phải đồng ý truy cập hoặc tiết lộ cho bên thứ ba do những trường hợp này.',
    'terms.item6.content1.list1':
      'Khi công việc sửa chữa được thực hiện khi dữ liệu của người dùng bị hỏng do lỗi của ứng dụng này, v.v.',
    'terms.item6.content1.list2':
      'Khi thực hiện công việc chuyển đổi cần thiết để phản ánh các chức năng đã thay đổi, mở rộng hoặc sửa đổi của hệ thống này trong dữ liệu của người dùng do sửa đổi để đáp ứng với sự thay đổi, mở rộng hoặc sửa đổi luật và quy định của ứng dụng này.',
    'terms.item6.content1.list3':
      'Khi thực hiện phân tích dữ liệu cần thiết sau khi thực hiện xử lý ẩn danh để cải thiện chất lượng của ứng dụng này',
    'terms.item6.content1.list4':
      'Khi có thể xác định một cách hợp lý rằng cần phải bảo vệ các lợi ích quan trọng của tính mạng, sức khỏe, tài sản công cộng, v.v.',
    'terms.item6.content1.list5': 'Khi có yêu cầu tiết lộ dựa trên luật và quy định',
    'terms.item6.content1.list6':
      'Khi đích của việc tiết lộ và mục đích của việc tiết lộ được trình bày và nhận được sự đồng ý của từng cá nhân từ người dùng.',

    'terms.item7.title': 'Điều 7 (Các vấn đề bị cấm)',
    'terms.item7.content1.title':
      'Khi sử dụng dịch vụ này, người dùng không được thực hiện bất kỳ hành vi nào thuộc hoặc được coi là thuộc bất kỳ mục nào sau đây.',
    'terms.item7.content1.list1': 'Vi phạm thỏa thuận này, v.v.',
    'terms.item7.content1.list2':
      'Để sử dụng dịch vụ này cho các mục đích khác với mục đích mà bản thân người dùng sử dụng nó.',
    'terms.item7.content1.list3':
      'Sử dụng thông tin thu được thông qua việc sử dụng dịch vụ này và các dịch vụ riêng lẻ cho mục đích thương mại.',
    'terms.item7.content1.list4':
      'Trạng thái hợp đồng liên quan đến việc sử dụng dịch vụ này và các dịch vụ riêng lẻ, hoặc các quyền hoặc nghĩa vụ phát sinh từ hợp đồng này, có thể được một bên thứ ba (một người nhận các dịch vụ riêng lẻ cùng lúc với người dùng) có được mà không cần sự đồng ý trước của Công ty hoặc nhà cung cấp. Chuyển đến (không bao gồm), chuyển nhượng, cho vay hoặc cung cấp làm tài sản thế chấp',
    'terms.item7.content1.list5': 'Đăng ký thông tin sai khi sử dụng dịch vụ này',
    'terms.item7.content1.list6':
      'Vi phạm bản quyền, quyền thương hiệu, quyền thiết kế, quyền bằng sáng chế và các quyền sở hữu trí tuệ khác của Công ty và các nhà cung cấp của Công ty',
    'terms.item7.content2.title':
      'Khi sử dụng ứng dụng này, người dùng sẽ không thực hiện bất kỳ hành động nào thuộc hoặc được coi là thuộc bất kỳ mục nào sau đây.',
    'terms.item7.content2.list1':
      'Truyền hoặc sử dụng bất kỳ chương trình hoặc dữ liệu có hại nào bao gồm cả vi rút máy tính tới ứng dụng này',
    'terms.item7.content2.list2': 'Sử dụng ứng dụng này theo cách cố ý vi phạm sở hữu trí tuệ của bên thứ ba',
    'terms.item7.content2.list3':
      'Ngoài phạm vi được Thỏa thuận này cho phép, bạn hoặc bên thứ ba có thể đăng ký thông tin, thay đổi cài đặt hoặc sửa đổi, chỉnh sửa, sao chép, tháo rời hoặc đảo ngược hệ thống này. Biên dịch và / hoặc thiết kế đối chiếu.',
    'terms.item7.content2.list4': 'Các hành vi khác mà Công ty cho là không phù hợp',
    'terms.item7.content3.title':
      'Nếu chúng tôi xác nhận rằng người dùng đã thực hiện các hành vi được chỉ định trong từng mục của hai đoạn trên hoặc nếu chúng tôi thừa nhận một cách hợp lý rằng các hành vi đó đã được thực hiện, chúng tôi sẽ tạm ngừng quyền truy cập của người dùng bất kỳ lúc nào và cung cấp các dịch vụ riêng lẻ. Bạn có thể hủy bỏ hoặc từ chối việc sử dụng.',

    'terms.item8.title': 'Điều 8 (Gián đoạn, tạm ngừng, v.v. của ứng dụng này)',
    'terms.item8.description':
      'Công ty sẽ tạm ngừng hoặc đình chỉ việc sử dụng tất cả hoặc một phần của ứng dụng này sau khi đã thông báo trước cho người dùng mà không chịu bất kỳ trách nhiệm nào nếu nó thuộc bất kỳ lý do nào được nêu trong các mục sau giả sử bạn có thể. Tuy nhiên, trong trường hợp khẩn cấp, thông báo trước có thể được bỏ qua.',
    'terms.item8.content1.list1': 'Khi thực hiện bảo trì thường xuyên hoặc khẩn cấp thiết bị hoặc phần mềm',
    'terms.item8.content1.list2':
      'Trong trường hợp ngừng cung cấp hoặc không cung cấp dịch vụ do máy chủ đám mây đã ký hợp đồng của chúng tôi, đường dây liên lạc, nguồn điện hoặc phán quyết riêng của nhà cung cấp dịch vụ công cộng khác hoặc do trường hợp bất khả kháng',
    'terms.item8.content1.list3':
      'Khi có lệnh tạm ngừng kinh doanh hoặc sự chỉ đạo, yêu cầu của cơ quan hành chính, cơ quan tư pháp vì những lý do không thể cho Công ty',
    'terms.item8.content1.list4':
      'Khi xảy ra sự cố (bao gồm cả lỗi và lỗi) trên ứng dụng này hoặc sự cố với thiết bị của hệ thống này',
    'terms.item8.content1.list5':
      'Khi việc cung cấp hệ thống này bị cản trở bởi hoạt động trái phép hoặc không chính xác của người dùng',
    'terms.item8.content1.list6':
      'Khi có quyền truy cập trái phép từ bên thứ ba vào ứng dụng này hoặc thiết bị cho ứng dụng này',
    'terms.item8.content1.list7': 'Khi cần khởi động lại ứng dụng này hoặc thiết bị cho ứng dụng này',
    'terms.item8.content1.list8':
      'Chúng tôi đã xác định rằng cần phải cung cấp một môi trường sử dụng ổn định cho tất cả người dùng ứng dụng này trong các trường hợp như khi quyền truy cập vào ứng dụng này tăng lên đáng kể và quá tải được áp dụng cho hoạt động của dịch vụ này nếu bạn đã làm điều này',
    'terms.item8.content1.list9': 'Khi có một hành vi tương ứng với "vấn đề bị cấm"',
    'terms.item8.content1.list10':
      'Ngoài ra, khi chúng tôi đánh giá rằng việc gián đoạn bảo trì là cần thiết cho hoạt động của dịch vụ này',

    'terms.item9.title': 'Điều 9 (Trách nhiệm của chúng tôi)',
    'terms.item9.content1.title':
      'Công ty sẽ cố gắng cung cấp thông tin chính xác và mới nhất về dữ liệu, thông tin bản đồ, thông tin tọa độ GPS, v.v. được đăng trong ứng dụng này và các dịch vụ riêng lẻ, nhưng với thông tin chính xác và mới nhất tại thời điểm người dùng sử dụng. không đảm bảo rằng có.',
    'terms.item9.content2.title':
      'Ngay cả khi ứng dụng này liên kết đến trang web của nhà cung cấp, v.v., chúng tôi không chịu trách nhiệm về trang web đó. Ngoài ra, chúng tôi sẽ không chịu trách nhiệm cho bất kỳ thiệt hại nào gây ra bởi hoặc liên quan đến việc sử dụng trang web.',
    'terms.item9.content3.title':
      'Nếu người dùng gây thiệt hại cho bên thứ ba bao gồm cả nhà cung cấp thông qua việc sử dụng ứng dụng này, người dùng sẽ tự chịu rủi ro và chi phí giải quyết và Công ty hoàn toàn không chịu trách nhiệm ...',
    'terms.item9.content4.title':
      'Công ty sẽ không chịu trách nhiệm về bất kỳ thiệt hại nào gây ra bởi việc không thể sử dụng dịch vụ này do thiết bị đầu cuối thông tin di động và đường dây liên lạc để sử dụng ứng dụng này.',
    'terms.item9.content5.title':
      'Về thỏa thuận này, nếu Công ty bồi thường thiệt hại cho người sử dụng thì số tiền sẽ là tổng số tiền mà cơ quan giải quyết đơn này đã giải quyết trong 3 tháng gần nhất khi xảy ra thiệt hại hoặc cơ quan giải quyết đơn này trong lần cuối cùng. 1 tháng. Giới hạn trên là mức cao hơn của số tiền được thanh toán gấp 3 lần số tiền được thanh toán. Tuy nhiên, điều này không áp dụng nếu thiệt hại do cố ý hoặc sơ suất thô bạo của chúng tôi.',

    'terms.item10.title': 'Điều 10 (Trách nhiệm của Người dùng)',
    'terms.item10.content1.title':
      'Để sử dụng ứng dụng này, người dùng phải chuẩn bị một thiết bị đầu cuối thông tin di động và môi trường giao tiếp và duy trì trạng thái có thể sử dụng với trách nhiệm và chi phí của chính người dùng.',
    'terms.item10.content2.title':
      'Người dùng sẽ tự chịu rủi ro khi quản lý quyền truy cập vào ứng dụng này. Ngoài ra, nếu dịch vụ này hoặc dịch vụ cá nhân được sử dụng với quyền truy cập của người dùng, ngay cả khi nó được sử dụng bởi bên thứ ba không phải là người dùng, Công ty có thể coi đó là do người dùng giả sử.',
    'terms.item10.content3.title':
      'Người dùng sẽ phải chịu trách nhiệm về bất kỳ thiệt hại nào gây ra cho Công ty hoặc nhà cung cấp do vi phạm các Điều khoản và Điều kiện này.',

    'terms.item11.title': 'Điều 11 (Quầy tham vấn / Luật điều chỉnh)',
    'terms.item11.content1.title': 'Thỏa thuận này và mọi quy tắc kèm theo, v.v. sẽ được hiểu theo luật Singapore.',
    'terms.item11.content2.title':
      'Nếu có bất kỳ nghi ngờ nào về hoạt động hoặc cách giải thích của thỏa thuận này, v.v. trong việc sử dụng ứng dụng này, dịch vụ này và các dịch vụ cá nhân, người dùng có thể yêu cầu thông tin liên hệ sau.' +
      '<br>Địa chỉ email：maas@willer.co.jp',
    'terms.item11.content3.title':
      'Đối với các tranh chấp liên quan đến thỏa thuận này, tòa án có thẩm quyền đối với địa chỉ đã đăng ký của Công ty trong một vụ kiện dân sự tại Cộng hòa Singapore sẽ là tòa án sơ thẩm theo thỏa thuận độc quyền. Tuy nhiên, nếu luật bắt buộc hiện hành cho phép khởi kiện tại một tòa án khác, thì điều khoản đó sẽ được áp dụng.',

    // Verify mobile account
    'verify_mobile_account.register_email': 'Địa chỉ email đã đăng ký',

    // Verify subcription
    'verify_subcription.family_option_app': 'Đăng ký gói gia đình',
    'verify_subcription.family_option_complete': 'Đăng ký gói gia đình thành công.',
    'verify_subcription.failed_apply_family_option': 'Đăng ký gói gia đình thất bại.',

    // Mobile reissue password
    'mobile_reissue_pass.enter_password': 'Hãy nhập mật khẩu mới',
    'mobile_reissue_pass.enter_pass_confirm': 'Hãy nhập 2 lần để xác nhận',
    'mobile_reissue_pass.re_enter_password': 'Nhập lại mật khẩu',
    'mobile_reissue_pass.enter_password_again': 'Hãy nhập lại mật khẩu',
    'mobile_reissue_pass.enter_content': 'Nhập mật khẩu sai',
    'mobile_reissue_pass.token_failed': 'Kiểm tra lỗi token',
    'validation.required.password': 'Hãy nhập mật khẩu',
    'validation.required.re_enter_password': 'Hãy nhập lại mật khẩu',

    // Mobile repassword complete
    'mobile_repass_complete.new_password': 'Mật khẩu mới',
    'mobile_repass_complete.set': 'đã được thiết lập',
    'mobile_repass_complete.launch_app': 'Khởi động app',
    'mobile_repass_complete.login': 'Vui lòng đăng nhập',

    // tutorial app
    'tutorial_app.title': 'Thay đổi cách bạn di chuyển hàng ngày',
    'tutorial_app.description': 'Gói dịch vụ',
    'tutorial_app.content1': 'mobi là loại hình di chuyển mới',
    'tutorial_app.content2': 'dành cho mọi người.',
    'tutorial_app.content3': 'Đặt chuyến mobi qua ứng dụng này để có thể di chuyển hiệu quả nhất, với giá tốt nhất với tuyến đường đi tối ưu nhất.',
    'tutorial_app.content4': '',
    'tutorial_app.feature.title': 'Đặc điểm',
    'tutorial_app.feature.description': 'Đặc điểm của mobi',
    'tutorial_app.location.title': 'Di chuyển tiện lợi',
    'tutorial_app.location.content1': 'Chúng tôi sẽ đón bạn tại điểm đón ',
    'tutorial_app.location.content2': 'và đưa bạn đến nơi mong muốn.',
    'tutorial_app.car.title': 'Xe chạy xung quanh khu vực bạn sinh sống',
    'tutorial_app.car.content1': 'mobi chạy liên tục quanh khu vực bạn sinh sống.',
    'tutorial_app.car.content2': 'Đặt chuyến qua APP này, xe sẽ đến đón bạn.',
    'tutorial_app.wallet.title': 'Thân thiện với ví tiền của bạn',
    'tutorial_app.wallet.content1': 'Phương tiện di chuyển đi chung cùng mọi người trong khu phố, giá tốt hơn, thân thiện hơn với ví tiền của bạn',
    'tutorial_app.wallet.content2': '',
    'tutorial_app.wallet.content3': '',
    'tutorial_app.request.title': 'Đặt chỗ',
    'tutorial_app.request.description': 'Cách đặt mobi',
    'tutorial_app.step1.title': 'Bước 1',
    'tutorial_app.step1.content': 'Nhập địa điểm lên xe và nơi bạn muốn đến',
    'tutorial_app.step2.title': 'Bước 2',
    'tutorial_app.step2.content': 'Chọn mobi trong danh sách phương tiện',
    'tutorial_app.step3.title': 'Bước 3',
    'tutorial_app.step3.content': 'Lên xe tại địa điểm lên xe đã chọn',
  },
};

export default vi;
